import React, { Component } from "react";
import { Row } from "react-bootstrap";
import Moment from "moment";
import "moment/locale/th";
class UserImage extends Component {
    render() {
        const { user, dataTime, size } = this.props;
        return (
            <Row style={{ margin: 10 }}>
                {user.imageUrl ?
                    <img
                        src={user.imageUrl}
                        style={{
                            width: size,
                            height: size,
                            borderRadius: size / 2
                        }}
                        alt="user"
                    />
                    :
                    <div
                        style={{
                            backgroundColor: "#06274C",
                            width: size,
                            height: size,
                            borderRadius: size / 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                    >
                        <span
                            style={{ color: "#ffffff", fontSize: 16 }}>
                            {user.firstName[0].toUpperCase()}
                        </span>

                    </div>
                }

                <div style={{ marginLeft: 10 }}>
                    {user.firstName}{" "}{user.lastName}
                    <p
                        style={{
                            color: "#979797",
                            fontSize: 14
                        }}>
                        {Moment(dataTime).format("LLL")}
                    </p>
                </div>
            </Row>
        )
    }
}

export default UserImage;