import React, { Component } from "react";
import { Navbar, NavDropdown, Nav, Row, Col } from "react-bootstrap";
import Can from "../Can";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getUserAuth, resetUserAuth } from '../../actions/UserAction';
import './styled.css';
class HeaderMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShow: false
        };
    }

    componentDidMount() {
        this.props.getUserAuth();
    }

    onSignOut = () => {
        this.props.resetUserAuth();
        this.props.history.replace("/");
    };
    profileMenu = () => {
        this.props.history.push("/profile");
    };

    handleToggle = () => {
        this.setState({ isShow: !this.state.isShow });
    }
    
    render() {
        const { userAuth } = this.props.userReducer;
        return (
            <div
                style={styles.container}>
                <Navbar
                    collapseOnSelect
                    expand="md"
                    variant="dark"
                    style={styles.navber}>
                    <Navbar.Brand href="/">
                        <img src={require('../../assets/Bg/Logo3.png')}
                            style={styles.logo}
                            alt='logo-clinicya' />
                            <h1 style={styles.clinicyaFont}>คลินิกยา</h1>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse>
                        <Nav className="mr-auto" />
                        <Nav>
                            <Nav.Link href="/" style={{ color: "white" , fontSize : 16 }}>หน้าแรก</Nav.Link>
                            <Nav.Link href="/medicine" style={{ color: "white" , fontSize : 16 }}>ค้นหายา</Nav.Link>
                            <Nav.Link href="/question" style={{ color: "white" , fontSize : 16 }}>ปรึกษาผู้เชี่ยวชาญ</Nav.Link>
                            {/* <Nav.Link href="/article" style={{ color: "white" }}>บทความ</Nav.Link> */}
                        <Nav>
                            {userAuth && userAuth.authenticated
                                ? (
                                    <NavDropdown
                                        id="nav-dropdown"
                                        onToggle={this.handleToggle}
                                        show={this.state.isShow}
                                        style={{
                                            right: 0,
                                            left: "initial"
                                        }}
                                        title={userAuth.imageUrl
                                            ? <img
                                                src={userAuth.imageUrl}
                                                style={styles.profileImage}
                                                alt="user" />
                                            : <div
                                                style={styles.lettersContainer}>
                                                <b style={styles.letters}>
                                                    {userAuth.firstName[0].toUpperCase()}
                                                </b>
                                            </div>}>
                                        <Row>
                                            <Col>
                                                <NavDropdown.Header>
                                                    {userAuth.firstName} {userAuth.lastName}
                                                </NavDropdown.Header>
                                                <Can
                                                    role={userAuth.role}
                                                    perform="my-question:list"
                                                    yes={() => (
                                                        <NavDropdown.Item href="/my-question">
                                                            <ion-icon name="text" /> คำถามของฉัน
                                                    </NavDropdown.Item>
                                                    )}
                                                    no={() => null} />
                                                <NavDropdown.Item href="/profile">
                                                    <ion-icon name="contact" /> โปรไฟล์
                                                </NavDropdown.Item>
                                                <NavDropdown.Divider />
                                                <NavDropdown.Item onClick={this.onSignOut}>
                                                    <ion-icon name="log-out" /> ออกจากระบบ
                                                </NavDropdown.Item>
                                            </Col>
                                        </Row>
                                    </NavDropdown>
                                )
                                : (
                                    <Nav.Link href="/login">เข้าสู่ระบบ</Nav.Link>
                                )}
                            </Nav>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}

const styles = {
    navber: {
        backgroundColor: "#4e98b8",
        boxShadow: `0 2px 7px 0 rgba(128, 128, 128, 0.2), 0 3px 10px 0 rgba(128, 128, 128, 0.19)`
    },
    logo: {
        position: 'sticky',
        width: "4vh",
        height: "4vh"
    },
    clinicyaFont: {
        padding: 2, fontSize: '3vh',
        color: "#FFFFFF"
    },
    profileImage: {
        width: 33,
        height: 33,
        borderRadius: "45%"
    },
    lettersContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#ffffff",
        width: 35,
        height: 35,
        borderRadius: "50%"
    },
    letters: {
        color: "#06274C",
        fontSize: 15
    }
}

const mapStateToProps = (state) => ({ userReducer: state.userReducer })

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        getUserAuth,
        resetUserAuth,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
