const service = {};

service.getTypeMedicine = () => {
    return type;
}


const type = [
    { id: 0, medicine:"analgesic" , name:"ยาแก้ปวด"},
    { id: 1, medicine:"antibiotic" , name:"ยาแก้อักเสบ" },
    
  ];


  
export default service;