import React, {Component} from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
class ModalMenu extends Component {
  onChangeImage = () => {
    this.refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.props.onUpload(blob);
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleHideModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ zIndex: 9999999 }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" >
            <span>อัปโหลดรูป</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <Row>
            <Col>
              <Cropper
                ref='cropper'
                src={this.props.imageUrl}
                style={{ height: 360, width: '100%' }}
                aspectRatio={1}
                guides={false}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col>
              <Button style={{backgroundColor:"#4e98b8",borderColor:"#4e98b8",borderRadius:"30px"}} onClick={this.onChangeImage}>อัปโหลด</Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalMenu;