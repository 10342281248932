import React, { Component } from "react";
import AuthGuardService from "../../shared/services/AuthGuardService";
import { Loading } from "../../components";
import { Card, Row, Button, Col } from "react-bootstrap";
import './style.css';

import CardDeck from 'react-bootstrap/CardDeck';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      signedIn: false,
    };
  }

  componentWillMount() {

    this.checkSignedIn();
  }

  checkSignedIn = async () => {
    this.setState({ loading: true });
    const signedIn = await AuthGuardService.isSignedIn();
    this.setState({ signedIn, loading: false });
  };

  render() {
    const { loading } = this.state;
    if (loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }
    return (
      <div>
        <div className="homehead1">
          <div className="container" style={{ paddingBottom: "5%" }}>
            <h1 style={{ paddingTop: "5%" }}>คลินิกยา (CLINIC-YA)</h1>
            <p>
              ให้ความรู้ที่ถูกต้องของการใช้ยาสำหรับผู้บริโภค รวมถึงมีบุคลากรทางด้านสุขภาพที่มีประสบการณ์ที่คอยให้คำปรึกษา
            </p>
            <p>
              <Button style={{ backgroundColor: "#4e98b8", color: "white",borderColor:"#4e98b8" }} href="#clinicya">เพิ่มเติม</Button>
            </p>
            <h1 style={{ paddingTop: "5%" }}>ฟีเจอร์</h1>
            <CardDeck>
              <Card>
                <Card.Body>
                  <Card.Title style={{ color: "#000000" }}>ค้นหายา</Card.Title>
                  <Card.Text>
                    เป็นส่วนของการค้นหาข้อมูลยาต่าง ๆ ตามข้อมูลยาแผนปัจจุบันที่ถูกต้อง ค้นหายาด้วยชื่อ ลักษณะ และค้นหาด้วยรูปภาพ
              </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title style={{ color: "#000000" }}>ปรึกษาผู้เชี่ยวชาญ</Card.Title>
                  <Card.Text>
                    เป็นช่องทางที่สามารถส่งข้อมูลเพื่อขอปรึกษาการใช้ยากับเภสัชกร หรือบุคลากรด้านสุขภาพ ตั้งกระทู้คำถามใหม่ ตอบกระทู้และความคิดเห็น
                      </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title style={{ color: "#000000" }}>อัลบั้มยา</Card.Title>
                  <Card.Text>
                    เป็นส่วนของการเก็บบันทึกข้อมูลรูปภาพยา โดยผู้ใช้สามารถเก็บบันทึกรูปภาพ และเพิ่มรายละเอียดเกี่ยวกับยาลงในฐานข้อมูลได้
              </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
          </div>
        </div>
        <div className="container">
          <h1 id="clinicya" style={{ paddingTop: "5%" }}>คลินิกยา</h1>
          <p>
            จุดประสงค์ คือ การให้ความรู้ที่ถูกต้องของการใช้ยา กับผู้บริโภคลดความผิดพลาดในการรับประทานยา <br />
            และผลข้างเคียงที่เกิดขึ้นจากยา
          </p>
          <div className="box">
            <CardDeck>
              <Card>
                <Card.Body>
                  <Card.Title style={{ color: "#000000" }}>เว็บไซต์</Card.Title>
                  <Card.Text>
                    เป็นเว็บไซต์ที่พัฒนาขึ้นสำหรับผู้ใช้ และบริโภค ยา รวมถึงบุคลากรทางด้านสุขภาพ ที่จัดเก็บ ข้อมูลยาแผนปัจจุบันที่ถูกต้อง
                </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title style={{ color: "#000000" }}>ข้อมูล</Card.Title>
                  <Card.Text>
                    สามารถค้นหาข้อมูลยาต่าง ๆ ตามชื่อ, อาการของโรค, ลักษณะรูปร่าง, สี
                    และการค้นหาจากภาพถ่ายเม็ดยาจากฐานข้อมูลยาที่รวบรวมข้อมูลจากคณะเภสัชศาสตร์ มหาวิทยาลัยบูรพา
                </Card.Text>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title style={{ color: "#000000" }}>ช่องทาง</Card.Title>
                  <Card.Text>
                    มีช่องทางที่ประชาชนทั่วไปสามารถส่งข้อมูลเพื่อปรึกษาการใช้ยากับเภสัชกร หรือบุคลากรด้านสุขภาพ
                    จุดประสงค์สำคัญคือ การให้ความรู้ที่ถูกต้องของการใช้ยากับผู้บริโภค ลดความผิดพลาดในการรับประทานยา และผลข้างเคียงที่เกิดขึ้นจากยา
                </Card.Text>
                </Card.Body>
              </Card>
            </CardDeck>
          </div>
        </div>
        <div className="container" style={{ textAlign: "center", paddingTop: "5%", paddingBottom: "5%" }}>
          <Row>
            <Col>
              <Button variant="link" style={{ color: "#4e98b8" }} href="https://www.facebook.com/PharmaseaBUU/">
                <ion-icon style={{ color: "#4e98b8", fontSize: "10vh" }} name="logo-facebook" /><br />
                บูรพาเภสัช</Button>
            </Col>
            <Col>
              <Button variant="link" style={{ color: "#4e98b8" }} href="https://www.facebook.com/madiLab.informatics/">
                <ion-icon style={{ color: "#4e98b8", fontSize: "10vh" }} name="logo-facebook" /><br />
                MADI Lab</Button>
            </Col>
            <Col>
              <Button variant="link" style={{ color: "#4e98b8" }} href="https://www.informatics.buu.ac.th/2018/">
                <ion-icon style={{ color: "#4e98b8", fontSize: "10vh" }} name="globe" /><br />
                เว็บไซต์คณะวิทยาการสารสนเทศ</Button>
            </Col>
          </Row>
        </div>
        <div className="homehead3">
          <div className="container" style={{ paddingBottom: "5%" }}>
            <h1 style={{ paddingTop: "5%" }}>แอปพลิเคชัน (Application)</h1>
            <p>
              สามารถดาวน์โหลดแอปพลิเคชันในระบบปฏิบัติการ Android ของเราได้ที่นี่ ติดตั้งบนอุปกรณ์เคลื่อนที่ของคุณ เพื่อความสะดวกในการใช้งานมากยิ่งขึ้น
          </p>
            <Button style={{ backgroundColor: "#4e98b8", color: "white",borderColor:"#4e98b8" }} href="http://bit.ly/3152J0l">ดาวน์โหลด</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;