import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, ListGroup} from "react-bootstrap";
import { Loading, Pagination } from "../../../components";
import UserImage from "../UserImage";
class QuestionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    nextPage = (value) => {
        this.setState({ currentPage: value });
        this.props.onChangePage(value);
    }

    previousPage = (value) => {
        this.setState({ currentPage: value });
        this.props.onChangePage(value);
    }

    onChangeNumPage = (value) => {
        this.setState({ currentPage: value });
        this.props.onChangePage(value);
    }

    getDescription = (item, markSearch) => {
        let description = item.description;
        if (description.length > 155) {
            description = description.substr(0, 155)
        }
        if (markSearch && markSearch.trim()) {
            description = description.replace(markSearch, `<mark>${markSearch}</mark>`)
        }
        return (
            <div dangerouslySetInnerHTML={{ __html: description }} />
        )
    }

    getTopic = (item, markSearch) => {
        let topic = item.topic
        if (markSearch && markSearch.trim()) {
            topic = topic.replace(markSearch, `<mark>${markSearch}</mark>`);
        }
        return <div dangerouslySetInnerHTML={{ __html: topic }} />
    }

    render() {
        const { loading } = this.state;
        let { datas, currentPage, numberOfDatas, result, markSearch } = this.props;
        const numberOfPages = Math.ceil(numberOfDatas / result);
        if (loading) {
            return <Loading />
        }
        return (
            <div>
                <Card
                    style={{
                        padding: 20
                    }}>
                    {datas && datas.length > 0
                        ? datas.map((item, index) => {
                            return (
                                <Row
                                >
                                    <Col>
                                        <ListGroup>
                                            <Link
                                                to={`/questiondetail/${item._id}`}
                                                style={{
                                                    fontSize: '2.5vh',
                                                    color: "#4e98b8",
                                                    marginBottom: 5
                                                }}>
                                                {this.getTopic(item, markSearch)}
                                            </Link>
                                            <UserImage
                                                size={35}
                                                user={item.questioner}
                                                dataTime={item.createdAt}
                                            />
                                            <div
                                                style={{
                                                    fontSize: "2vh"
                                                }}>
                                                {this.getDescription(item, markSearch)}
                                            </div>

                                        </ListGroup>
                                        <Row>
                                            <Col lg={10} md={10} sm={9} xs={9}>
                                                <span
                                                    className="tag is-medium"
                                                    style={{
                                                        marginTop: 10,
                                                        fontSize: "1.8vh",
                                                        backgroundColor: "#4e98b8",
                                                        color: "white"
                                                    }}>
                                                    {item.category}
                                                </span>
                                            </Col>
                                            <Col lg={2} md={2} sm={3} xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                <span style={{ fontSize: 18, color: "#979797" }}><ion-icon name="chatbubbles" />{item.numOfAnswers}</span>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                            <Col>
                                            <Accordion>
                                                <Card style={{marginTop:25}}>
                                                    <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0" >
                                            <ion-icon name="chatbubbles"/>
                                                แสดงความคิดเห็น
                                            </Accordion.Toggle>
                                                    </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <QuestionDetail
                                                dataFromParent = {item._id}/>   
                                            </Card.Body>
                                            </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                            </Col>
                                        </Row> */}
                                        <hr style={{ backgroundColor: "#DADADA" }} />
                                    </Col>
                                </Row>
                            )
                        })
                        : <h1
                            style={{
                                fontSize: "2vh",
                                textAlign: "center"
                            }}>"ไม่พบคำถามในระบบ..."</h1>}
                </Card>
                {numberOfDatas > 0
                    ? <Row
                        style={{
                            marginTop: 50
                        }}>
                        <Col
                            lg={{
                                span: 4,
                                offset: 4
                            }}
                            md={{
                                span: 6,
                                offset: 3
                            }}
                            sm={{
                                span: 10,
                                offset: 1
                            }}
                            xs={{
                                span: 10,
                                offset: 1
                            }}>
                            <Pagination
                                numOfPages={numberOfPages}
                                currentPage={currentPage}
                                nextPage={this.nextPage}
                                previousPage={this.previousPage}
                                onChange={this.onChangeNumPage} />
                        </Col>
                    </Row>
                    : null
                }
                <div style={{
                    marginTop: 10
                }}>
                    <h1
                        style={{
                            fontSize: '14px',
                            color: "grey",
                            marginBottom: "2vh",
                            textAlign: "center"
                        }}>
                        ผลลัพธ์ทั้งหมด : {numberOfDatas} รายการ
                    </h1>
                </div>
            </div>
        );        
    }
}

export default QuestionList;
