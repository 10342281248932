import React from 'react'
import { Alert as RBAlert, Fade } from 'react-bootstrap'

class Alert extends React.Component {
  componentWillUnmount() {
    if (this.dismissTimeoutId) clearTimeout(this.dismissTimeoutId)
  }

  render() {
    // RBAlert's onDismiss is called when the 'x' on the RBAlert is clicked.
    if (this.props.show) {
      this.dismissTimeoutId = setTimeout(this.props.handleDismiss, 3000)
      return (
            <Fade in timeout={this.props.timeout || 500}>
              <RBAlert variant={this.props.variant || 'success'} style={this.props.style}>
                {this.props.children}
              </RBAlert>
            </Fade>
      )
    } else {
      return null
    }
  }
}

export default Alert