import { put, takeLatest } from 'redux-saga/effects';
import {
    SET_PAGE_REQUEST,
    SET_PAGE_SUCCESS,
    SET_PAGE_FAILURE,
} from "../actions/PageAction/types";
function* setPage(action) {
    try {
        yield put({ type: SET_PAGE_SUCCESS, json: action.page });
    } catch (error) {
        yield put({ type: SET_PAGE_FAILURE, json: "Set the page failure." });
    }
}

export default function * root() {
    yield takeLatest(SET_PAGE_REQUEST, setPage);
}
