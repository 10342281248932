import React, { Component } from "react";
import ArticleService from "../../../shared/services/ArticleService";
import './style.css'
import ModalMenu from '../../Profile/UploadImage';
import Spinner from 'react-bootstrap/Spinner'
import Alert from '../../../components/Alert'
class ImageUpload extends Component {
    state = {
        loading: false,
        imageUrl: "",
        image: "",
        showMessage: false
    };
    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    handleDismiss = () => {
        this.setState({ showMessage: false });
    };

    checkSize(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        return isLt2M;
    }

    onChangeImage = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (this.checkSize(file)) {
                this.getBase64(file, imageUrl => this.setState({
                    imageUrl: imageUrl,
                    modalShow: true
                }));
            } else {
                this.setState({ showMessage: true })
            }
        }
    }

    handleHideModal = () => {
        this.setState({ modalShow: false });
    }
    onUpload = async (file) => {
        this.setState({ loading: true })
        const data = await ArticleService.uploadSearchImage(file)
        this.setState({
            modalShow: false,
            image: data,
            loading: false
        });
        this.props.onChange(this.state.image)
    }
    render() {
        const { loading } = this.state
        if (loading) {
            return (
                <div style={{ paddingTop: "5%" }} className="container">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>

            )
        } else {
            return (
                <div>
                    <div className="container" style={{ width: 300, height: 70 }}>
                        <Alert
                            style={{ margin: "auto", color: "red", textAlign: "center", }}
                            show={this.state.showMessage}
                            variant="danger"
                            handleDismiss={this.handleDismiss}
                        >
                            ขนาดไฟล์เกิน 2 MB
                        </Alert>
                    </div>
                    <span className="btn btn-up btn-file" style={{ width: 200, height: 70, textAlign: "center", paddingTop: 15 }} >
                        <ion-icon name="cloud-upload"
                            style={{ marginRight: 10, fontSize: 25 }}></ion-icon>
                    อัปโหลดภาพ
                    <input
                            type="file"
                            accept="image/*"
                            onChange={this.onChangeImage} />
                    </span>
                    <p>
                        รองรับภาพ JPG และ PNG ที่มีขนาดไฟล์ไม่เกิน 2 MB เท่านั้น
                    </p>
                    <ModalMenu
                        show={this.state.modalShow}
                        imageUrl={this.state.imageUrl}
                        handleHideModal={this.handleHideModal}
                        onUpload={this.onUpload}
                    />
                </div>
            )
        }
    };
};
export default ImageUpload