import { RESET_USER_AUTH_REQUEST, GET_USER_AUTH_REQUEST } from './types';

export const getUserAuth = () => (
    {
        type: GET_USER_AUTH_REQUEST,
    }
);

export const resetUserAuth = () => (
    {
        type: RESET_USER_AUTH_REQUEST,
    }
);

