import React, { Component } from "react";
import UserService from "../../../shared/services/UserService";
import { Row, Col, Container, Button } from "react-bootstrap";
// import Button from "@material-ui/core/Button";
import { ClipLoader } from "react-spinners";
import SimpleReactValidator from "simple-react-validator";
class ConfirmPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            user: {},
            password: "",
            confirmPassword: ""
        }
        this.validator = new SimpleReactValidator({
            validators: {
                match: {  // name the rule
                    message: 'The Confirm Password confirmation does not match.',
                    rule: (val, params) => {
                        return val === params[0]
                    },
                    required: true  // optional
                }
            }
        });
    }

    componentDidMount() {
        const user = this.props.location.state.user || null;
        user ? this.setState({ user }) : this.props.history.goBack();
    }

    handleSubmit = async () => {
        if (this.validator.allValid()) {
            this.setState({ loading: true });
            const data = {
                password: this.state.password,
                confirmPassword: this.state.confirmPassword
            }
            const status = await UserService.verifyPassword(this.state.user._id, data);
            this.setState({ loading: false });
            status === 200 ? this.props.history.replace("/login") : console.log(status);
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    render() {
        const { loading, password, confirmPassword } = this.state;
        return (
            <Container>
                <h1 style={{ color: "#159EA3", fontSize: 30, textAlign: "center", padding: 30 }}>ตั้งรหัสผ่าน</h1>
                <Row data-aos="fade-up" className="justify-content-md-center">
                    <Col md={6}>
                        <Row>
                            <Col>
                                <p style={{ color: "#159EA3" }}>รหัสผ่าน :</p>
                                <input
                                    className="input"
                                    type="Password"
                                    value={password}
                                    placeholder="password"
                                    name="emailid"
                                    onChange={event => this.setState({ password: event.target.value })} />
                                <small style={{ color: "red" }}>{this.validator.message('password', password, 'required|min:8|max:24')}</small>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Col>
                                <p style={{ color: "#159EA3" }}>ยืนยันรหัสผ่าน :</p>
                                <input
                                    className="input"
                                    type="Password"
                                    value={confirmPassword}
                                    placeholder="ConfirmPassword"
                                    onChange={event => this.setState({ confirmPassword: event.target.value })}
                                    onKeyPress={event => {
                                        if (event.key === 'Enter') {
                                            this.handleSubmit()
                                        }
                                    }} />
                                <small style={{ color: "red" }}>{this.validator.message('confirmPassword', confirmPassword, `required|min:8|max:24|match:${this.state.password}`)}</small>
                            </Col>
                        </Row>
                        <Button
                            className="button is-medium is-fullwidth is-rounded "
                            style={{
                                backgroundColor: "#159EA3",
                                color: "white",
                                marginTop: 40,
                                marginBottom: 30
                            }}
                            onClick={this.handleSubmit}>
                            {loading
                                ? <ClipLoader sizeUnit={"px"} size={25} color={"#ffffff"} />
                                : <span>ยืนยัน</span>
                            }
                        </Button>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default ConfirmPassword;