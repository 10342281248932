import React, { Component } from "react";
import AuthGuardService from "../../../../shared/services/AuthGuardService";
import TopicService from "../../../../shared/services/TopicService";
import { confirmAlert } from "react-confirm-alert";
import { Row, Col, Card, Dropdown } from "react-bootstrap";
import CustomToggle from "../CustomToggle";
import { Loading, Can } from "../../../../components";
import UserImage from "../../UserImage";
class Topic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      signedIn: false,
      question: {},
      userAuth: {},
      numOfAnswers: 0
    };
  }
  componentWillMount() {
    this.getUserAuth();
  }

  getUserAuth = async () => {
    this.setState({ loading: true });
    const userAuth = await AuthGuardService.getUserAuth();
    const question = await TopicService.getQuestionById(this.props.questionId);
    this.setState({ userAuth, question, loading: false });
  };

  submit = item => {
    confirmAlert({
      title: "คำถาม",
      message: "คุณต้องการลบคำถามนี้ใช่หรือไม่?",
      buttons: [
        {
          label: "ใช่",
          onClick: () => this.onRemove(item)
        },
        {
          label: "ไม่ใช่",
          onClick: () => console.log("on close")
        }
      ]
    });
  };

  onEditQuestion(question) {
    this.props.history.push(`/editquestion/topic/${question._id}/editPost`);
  }

  onRemove = async () => {
    const res = await TopicService.deleteQution(this.state.question._id);
    if (res === 200) {
      this.props.history.replace('/question')
    }
  }
  handleClick(e) {
    e.preventDefault();

    this.props.onClick(e);
  }

  render() {
    const { userAuth, question, loading } = this.state;
    if (loading) {
      return (<Loading />);
    }
    return (
      <Row className="justify-content-md-center">
        <Col md={8} xs={12}>
          <Card data-aos="fade-down"
            style={{
              padding: 20,
              boxShadow: `0 2px 7px 0 rgba(128, 128, 128, 0.2), 0 3px 10px 0 rgba(128, 128, 128, 0.19)`
            }}
          >
            <Row>
              <Col>
                <p
                  className="card-header-title"
                  style={{ wordWrap: "break-word", fontSize: 18 }}
                >
                  {question.topic}
                </p>
              </Col>
            </Row>
            <Row
            >
              <Col xl={11} lg={11} md={11} sm={10} xs={10}>
                <UserImage
                  size={40}
                  user={question.questioner}
                  dataTime={question.createdAt}
                />
              </Col>
              <Col xl={1} lg={1} md={1} sm={2} xs={2}>
                <Can
                  role={userAuth.role}
                  perform="posts:edit"
                  data={{
                    userId: userAuth._id,
                    postOwnerId: question.questioner._id
                  }}
                  yes={() => (
                    <Row className="justify-content-md-right" style={{ padding: 5 }}>
                      <Col>
                        <Dropdown>
                          <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-custom-components"
                          >
                          </Dropdown.Toggle>
                          <Dropdown.Menu style={{ right: 0, left: "auto" }}>
                            <Dropdown.Item eventKey="1" onClick={() => this.onEditQuestion(question)}>
                              <ion-icon name="create" />
                              <span>แก้ไข</span>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="2" onClick={() => this.submit(question)}>
                              <ion-icon name="trash" />
                              <span>ลบ</span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  )}
                  no={() => null}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={{ fontSize: 16, whiteSpace: "pre-line" }}>{question.description}</p>
              </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
              <Col lg={10} md={10} sm={10} xs={10}>
                <span className="tag is-danger is-medium" style={{ marginRight: 10, fontSize: 14 }}>
                  {question.visibility}
                </span>
                <span className="tag is-primary is-medium" style={{ marginRight: 10, fontSize: 14 }}>
                  {question.category}
                </span>
              </Col>
              <Col lg={2} md={2} sm={2} xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <span style={{ fontSize: 15, color: "#979797" }}><ion-icon name="chatbubbles" />{question.numOfAnswers}</span>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row >

    );
  }
}

export default Topic;
