import React from 'react'
import ArticleService from '../../../shared/services/ArticleService';
import { Loading } from '../../../components';
import { Row, Col } from "react-bootstrap";
import './style.css'

class DetailArticle extends React.Component {
  constructor() {
    super();
    this.state = {
      datas: [],
      menu:
      {
        id: 'title'
      }

    }
  }

  componentWillMount = () => {
    const id = this.props.match.params.id
    this.dataPage(id)
  }

  dataPage = async id => {

    this.setState({ loading: true })
    const res = await ArticleService.getData(id);
    await this.setState({ dataItem: res.data, loading: false });

  }

  // nextPage = async () => {
  //   this.setState({ loading: true })
  //   const index = this.state.dataItem.index + 1
  //   const res = await ArticleService.changePage(index);
  //   await this.setState({ dataItem: res, loading: false });
  //   this.props.history.push(`/page:${this.state.dataItem.index}/${this.state.dataItem._id}`)


  // }

  // previousPage = async () => {
  //   this.setState({ loading: true })
  //   const index = this.state.dataItem.index - 1
  //   const res = await ArticleService.changePage(index);
  //   await this.setState({ dataItem: res, loading: false });
  //   this.props.history.replace(`/page:${this.state.dataItem.index}/${this.state.dataItem._id}`)
  // }

  render() {
    const { loading, dataItem } = this.state
    if (loading) {
      return <Loading />
    }
    return (
      <div data-aos="fade-up" >
        <Row style={{ justifyContent: "center" }}>
          {window.innerHeight > 900 ? <h1 style={{ textAlign: "center", fontSize: '30px', marginTop: 30, marginLeft: 30, marginRight: 30 }}>{dataItem.topic}</h1>
            : <h1 style={{ fontSize: '20px', marginTop: 30, marginLeft: 30, marginRight: 30 }}>{dataItem.topic}</h1>}</Row>
        <hr />
        <Row className="justify-content-md-center">
          <Row style={{ justifyContent: "center" }}>
            {this.props.match.params.type === "article" ?
              <Col md={8} xs={10} ><div className="custom-css" dangerouslySetInnerHTML={{ __html: dataItem.description }} /></Col> : null}
            {this.props.match.params.type === "infographic" ?
              <Col md={12} xs={12} style={{ textAlign: "center" }}>
                <img src={dataItem.imageUrl} alt="info" max-width="2vw" />
              </Col> : null}
          </Row>
        </Row>
        <hr style={{ marginTop: 50 }} />
        <p style={{ fontSize: "14px", textAlign: "center" }}>ขอขอบคุณข้อมูลจาก : <a href={dataItem.referent}>{dataItem.referent}</a></p>
        <br />
      </div>
    )
  }
}

export default DetailArticle;