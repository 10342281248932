import React from "react";

class MapApi extends React.Component {
  render() {
    return (
      
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3883.108694632296!2d100.92285291482538!3d13.281147540653357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3102b4e883ea74c3%3A0x66aafed9bfe23fbe!2z4LiE4LiT4Liw4Lin4Li04LiX4Lii4Liy4LiB4Liy4Lij4Liq4Liy4Lij4Liq4LiZ4LmA4LiX4Lio!5e0!3m2!1sen!2sth!4v1547721911851"
              width='100%'
              height={480}
            >
              แผนที่
        </iframe>
         
    );
  }
}

export default MapApi;
