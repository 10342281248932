import React, { Component } from "react";
import AdvancedSearch from "./AdvancedSearch/index";
import * as DrugService from "../../shared/services/DrugService";
import { Loading } from "../../components";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Pagination from "../../components/Pagination";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setPage } from '../../actions/PageAction';
import './style.css';

class Medicine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      searching: false,
      visibleAds: false,
      validated: false,
      shape: {},
      colors: [],
      type: {},
      value: "",
      search: "",
      datas: [],
      filteredData: [],
      suggestions: [],
      statusSearch: "all",
      visibleAutoComplete: false,
      medicineDatas: [],
      numberOfPages: 0,
      result: 12,
      numberOfMedicine: 0
    };
    this.scroller = null
  }

  componentWillMount() {
    this.getDrugAll();
  }

  getDrugAll = async () => {
    this.setState({ loading: true });
    const { result } = this.state
    const currentPage = this.props.pageReducer.currentPage;
    const data = await DrugService.getDrugAll(currentPage - 1, result)
    this.setState({
      datas: data.datas,
      numberOfPages: Math.ceil(data.numberOfDatas / result),
      numberOfMedicine: data.numberOfDatas,
      loading: false
    })
    window.scroll(0, 0);
  }

  onEnter = async (e) => {
    if (e.key === "Enter") {
      const value = e.target.value
      await this.props.setPage(1);
      await this.setState({ statusSearch: "name", search: value })
      this.onCloseAutoComplete();
      this.fetchRecords();
    }
  }

  onChange = async event => {
    const search = event.target.value
    await this.setState({
      search: search
    });

    if (!this.state.searching) {
      this.setState({ searching: true })
      if (search.trim()) {
        const dataSample = await DrugService.getMedicineName(search)
        this.setState({ suggestions: dataSample })
      } else {
        this.setState({ suggestions: [] })
      }
      this.setState({ searching: false })
    }
  };

  onSelect = async item => {
    await this.setState(() => ({
      search: item.text[0],
    }))
  };

  onCloseAutoComplete = () => {
    setTimeout(() => {
      this.setState(() => ({
        visibleAutoComplete: false
      }))
    }, 200)

  };


  fetchRecords = async () => {
    const { search, result, statusSearch, colors, shape, type } = this.state
    const currentPage = this.props.pageReducer.currentPage;
    this.setState({ loading: true })
    if (statusSearch === "name") {
      const data = await DrugService.getMedicineByName(search, currentPage - 1, result);
      this.setState({
        datas: data.datas,
        numberOfPages: Math.ceil(data.numberOfDatas / result),
        numberOfMedicine: data.numberOfDatas,
        loading: false
      })
    }
    else if (statusSearch === "select") {
      if (colors === [] && shape === "" && type === "") {
        await this.setState({ datas: [], data: [] })
        this.setState({ statusSearch: "all" })
        this.getDrugAll()
      }
      else {
        const colorSelect = colors.map((item) => item.value)
        const body = { colors: colorSelect, shape: shape.value, type: type.value }
        const data = await DrugService.getMedicineByFeture(body, currentPage - 1, result)
        await this.setState({
          datas: data.datas,
          numberOfPages: Math.ceil(data.numberOfDatas / result),
          numberOfMedicine: data.numberOfDatas,
          loading: false,
          data: []
        })
      }
    }
    window.scroll(0, 0);
  }

  searchMedicine = async () => {
    if (this.state.search.trim()) {
      await this.props.setPage(1);
      await this.setState({ statusSearch: "name" })
      this.fetchRecords()
    }
    else {
      this.setState({ statusSearch: "all" })
      this.getDrugAll()
    }
  }

  searchSelectMedicine = async () => {
    await this.props.setPage(1);
    await this.setState({ statusSearch: "select" })
    this.fetchRecords()
  }

  showAdvancedSearch = () => {
    this.setState({ visibleAds: true });
  };


  hideAdvancedSearch = async () => {
    await this.setState({
      visibleAds: false,
      statusSearch: "all",
      search: "",
      numberOfPages: 0,
      colors: [],
      shape: "",
      type: "",
      data: []
    })
    this.props.setPage(1);
    this.getDrugAll()
  };
  showSearchImage = () => {
    this.props.history.push({
      pathname: '/ImageSearch'
    })
  }

  setDataSearchImage = async (index, shape, shapePoint, color, colorPoint) => {
    this.props.history.push({
      pathname: `/ListResult`,
      state: {
        index: index,
        shape: shape,
        shapePoint: shapePoint,
        color: color,
        colorPoint: colorPoint,
        imageUrl: this.state.imageUrl,
      }
    })
  }

  onSelectMedicine = medicine => {
    this.setState({ type: medicine });
  };

  onSelectShape = shape => {
    this.setState({ shape: shape });
  };

  onSelectColor = colors => {
    this.setState({ colors: colors });
  };

  nextPage = async (value) => {
    if (this.state.statusSearch === "name" || this.state.statusSearch === "select") {
      await this.props.setPage(value);
      this.fetchRecords()
    }
    else if (this.state.statusSearch === "all") {
      await this.props.setPage(value);
      this.getDrugAll()
    }
  }

  previousPage = async (value) => {
    if (this.state.statusSearch === "name" || this.state.statusSearch === "select") {
      await this.props.setPage(value);
      this.fetchRecords()
    }
    else if (this.state.statusSearch === "all") {
      await this.props.setPage(value);
      this.getDrugAll()
    }
  }

  onChangeNumPage = async (value) => {
    if (this.state.statusSearch === "name" || this.state.statusSearch === "select") {
      await this.props.setPage(value);
      this.fetchRecords()
    } else if (this.state.statusSearch === "all") {
      await this.props.setPage(value);
      this.getDrugAll()
    }
  }

  renderSuggestions = () => {
    const { suggestions } = this.state
    if (suggestions.length === 0) {
      return null
    }
    return (
      <ul className="suggestions">
        {
          suggestions.map((item, index) => {
            return (
              <li
                key={index}
                style={{ padding: 5, cursor: "pointer" }}
                onClick={() => this.onSelect(item)}
              >
                {item.text}<ion-icon name="search" style={{ color: "#159EA3", fontSize: 20, verticalAlign: "middle", float: "right" }} />
              </li>
            )
          })
        }
      </ul>
    )
  }


  render() {
    const { loading, visibleAds, datas, numberOfPages, numberOfMedicine } = this.state;
    const { currentPage } = this.props.pageReducer;
    if (loading) {
      return <Loading />
    } else {
      return (
        <Container onScroll={this.handleScroll} ref={(scroller) => this.scroller = scroller}>
          {visibleAds ? (
            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
              <p style={{ fontSize: 30, color: "#4e98b8", textAlign: "center" }}>ค้นหาเพิ่มเติม</p>
              <AdvancedSearch
                onSelectColor={this.onSelectColor}
                onSelectShape={this.onSelectShape}
                onSelectMedicine={this.onSelectMedicine}
                type={this.state.type}
                shape={this.state.shape}
                colors={this.state.colors}
              />
              <Row className="justify-content-md-center">
                <Col md={12} style={{ marginTop: 50, textAlign: "center" }}>
                  <Button
                    style={{ marginRight: 10, width: 150, color: "white", backgroundColor: "#4e98b8", borderRadius: "30px", borderColor: "#4e98b8" }}
                    onClick={this.searchSelectMedicine}
                  >
                    <p style={{ color: "white" }}>ค้นหา</p>
                  </Button>
                  <Button
                    size="md"
                    onClick={this.hideAdvancedSearch}
                    style={{ width: 150, borderColor: "#4e98b8", backgroundColor: "#4e98b8", borderRadius: "30px" }}
                  >
                    <p style={{ color: "white" }}>ย้อนกลับ</p>
                  </Button>
                </Col>
              </Row>
            </div>
          )
            :
            (
              <div style={{ paddingTop: 30, paddingBottom: 20 }}>
                <div >
                  <p style={{ fontSize: 30, color: "#4e98b8", textAlign: "center" }}>ค้นหาชื่อยา</p>
                  <Row className="justify-content-md-center" style={{ marginTop: 20 }}>
                    <Col sm={10}>
                      <input
                        className="input-custom"
                        type="text"
                        style={{
                          width:"100%",
                          lineHeight: "40px",
                          borderStyle: "solid",
                          borderWidth: ".75px .75px .75px .75px",
                          borderColor: "#d2d2d2",
                          outline: "none",
                          borderTopLeftRadius: 25,
                          borderTopRightRadius: 25,
                          borderBottomLeftRadius: this.state.suggestions.length > 0 && this.state.visibleAutoComplete ? 0 : 25,
                          borderBottomRightRadius: this.state.suggestions.length > 0 && this.state.visibleAutoComplete ? 0 : 25,
                          padding: 5,
                          paddingLeft: 10,

                        }}
                        value={this.state.search}
                        placeholder="ป้อนชื่อยา เช่น Celebrex 400 mg"
                        onBlur={this.onCloseAutoComplete}
                        onFocus={() => this.setState({ visibleAutoComplete: true })}
                        onChange={this.onChange}
                        onKeyPress={this.onEnter}
                      />
                      {this.state.visibleAutoComplete ? this.renderSuggestions() : null}
                    </Col>
                    <Col sm={2} style={{ textAlign: "center" }}>
                      <Button className="custom"
                        style={{
                          backgroundColor: "#4e98b8",
                          size: "sm",
                          width: "150px",
                          borderRadius: "30px",
                          borderColor: "#4e98b8",
                        }}
                        onClick={this.searchMedicine}
                      >
                        <p style={{ color: "white" }}>ค้นหา</p>
                      </Button>
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center" >
                    <Col style={{ marginTop: 40, textAlign: "center" }}>
                      <div className="mb-2">

                        <Button
                          variant="flat"
                          style={{ width: 200, margin: 10, height: 60 }}
                          onClick={this.showAdvancedSearch}>
                          ค้นหาเพิ่มเติม
                        </Button>
                        <Button
                          variant="flat"
                          style={{ width: 200, height: 60 }}
                          onClick={this.showSearchImage}>
                          ค้นหาด้วยภาพถ่าย
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          <hr />
          {(datas && datas.length > 0) ?
            <Row    >
              {datas.map((item, index) => (
                <Col data-aos="fade-up" key={index} md={4} lg={3} sm={12} xs={12} style={{ padding: 12 }}>
                  <Card >
                    {item.images.length > 0 ?
                      <LazyLoadImage
                        alt={item.brandName[0]}
                        src={item.images[0]}
                        style={{ width: '100%', height: 200, objectFit: "cover" }}
                        effect="blur" />
                      :
                      <Card.Img variant="top" style={{ width: '100%', height: 200, objectFit: "cover" }} src={require("../../assets/Drugs/no-image.jpg")} />
                    }
                    <Card.Body>
                      <Card.Title style={{ fontSize: '16px', }}>{item.brandName}</Card.Title>
                      <Card.Title style={{ fontSize: '16px', }}>สี : {item.colors.map((color, index) => { return item.colors.length > 1 ? (`${index + 1}.) ${color} `) : (`${color} `) })}</Card.Title>
                      <Card.Title style={{ fontSize: '16px', }}>รูปทรง : {item.shape}</Card.Title>
                      <Card.Footer style={{ textAlign: 'center' }}>
                        <Link
                          to={`/wiki/medicine/${item._id}`}
                          className='button' style={{ borderRadius: 25, width: '100%', borderColor: "#4e98b8", color: "#4e98b8" }}
                        >
                          รายละเอียดเพิ่มเติม
                      </Link>
                      </Card.Footer>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            :
            <div>
              <h1 style={{ textAlign: "center", fontSize: '60wh', color: "#4e98b8" }}>"ไม่พบข้อมูลยาในระบบ"</h1>
            </div>
          }
          {numberOfMedicine > 0 ?
            <Row style={{ marginTop: 50 }}>
              <Col lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }} sm={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                <Pagination
                  numOfPages={numberOfPages}
                  currentPage={currentPage}
                  nextPage={this.nextPage}
                  previousPage={this.previousPage}
                  onChange={this.onChangeNumPage}
                />
              </Col>
            </Row>
            : null
          }
          <div style={{ marginTop: 10 }}>
            <h1 style={{ fontSize: '14px', color: "grey", marginBottom: "2vh", textAlign: "center" }}>
              ผลลัพธ์ทั้งหมด : {this.state.numberOfMedicine} รายการ
            </h1>
          </div>
        </Container >
      );
    }
  }
}

const mapStateToProps = (state) => ({ pageReducer: state.pageReducer })

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setPage,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Medicine);