import React, { Component } from "react";
import * as DrugService from "../../../shared/services/DrugService";
import colorService from "../../../shared/services/ColorService";
import shapeService from "../../../shared/services/ShapeService";
import { Loading } from "../../../components";
import { Container, Row, Col, Card, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { setPage } from '../../../actions/PageAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './style.css';

class ListResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            imageUrl: "",
            shape: {},
            colors: [],
            type: {},
            datas: [],
            data: [],
            setdata: [],
            result: 12,
            numberOfMedicine: 0,
            numberOfPages: 1,
            currentPage: 0
        };
    }
    async componentWillMount() {
        try {
            this.setState({
                imageUrl: this.props.location.state.imageUrl,
                data: this.props.location.state.data
            })
            const index = this.props.location.state.index
            var shape = this.props.location.state.shape
            const shapePoint = this.props.location.state.shapePoint
            const color = this.props.location.state.color
            const colorPoint = this.props.location.state.colorPoint

            const colors = await colorService.getAllMainColor();
            const shapes = await shapeService.getShapeAll();

            if (shape === 'circle') {
                shape = 'round'
            }
            colors.map((item, index) => {
                return item.colorName === color[0] ? (this.setState({ colors: [{ value: item._id, label: item.colorName, color: item.hex }] })) : null
            });
            shapes.map((item, index) => {
                return item.shape === shape ? (this.setState({ shape: { value: item.shape, label: item.shape, imgUrl: item.imageUrl } })) : (null)
            });
            this.setState({
                type: "",
                resultText: `ลำดับที่: ${index + 1} สี: ${color} (ความคล้าย: ${colorPoint}%) รูปทรง: ${shape} (ความคล้าย: ${shapePoint}%)`,
            })
            this.fetchRecords()
        } catch (error) {
            this.props.history.goBack()
        }
    }
    fetchRecords = async () => {
        const { result, colors, shape, type } = this.state
        const currentPage = this.props.pageReducer.currentPage;
        this.setState({ loading: true })
        if (colors === [] && shape === "" && type === "") {
            await this.setState({ datas: [] })
        }
        else {
            const colorSelect = colors.map((item) => item.value)
            const body = { colors: colorSelect, shape: shape.value, type: type }
            const data = await DrugService.getMedicineByFeture(body, currentPage - 1, result)
            await this.setState({
                datas: data.datas,
                numberOfPages: Math.ceil(data.numberOfDatas / result),
                numberOfMedicine: data.numberOfDatas,
                loading: false,
            })
        }
        window.scroll(0, 0);
    }

    handleBackWard = async () => {
        this.props.history.push({
            pathname: "/ImageSearch",
            state: {
                data: this.state.data,
                imageUrl: this.state.imageUrl
            }

        })
    }

    nextPage = async (value) => {
        await this.props.setPage(value);
        this.fetchRecords()
    }

    previousPage = async (value) => {
        await this.props.setPage(value);
        this.fetchRecords()
    }

    onChangeNumPage = async (value) => {
        await this.props.setPage(value);
        this.fetchRecords()
    }

    render() {
        const { loading, numberOfPages, resultText, datas, imageUrl } = this.state;
        const { currentPage } = this.props.pageReducer;
        if (loading) {
            return <Loading />
        } else {
            return (
                <Container onScroll={this.handleScroll} ref={(scroller) => this.scroller = scroller}>
                    <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                        <p style={{ fontSize: 30, color: "#4e98b8", textAlign: "center" }}>รายการของผลลัพธ์</p>
                        <Row className="justify-content-md-center">
                            <Col style={{ marginTop: 50, textAlign: "center" }}>
                                <Row className="justify-content-md-center" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                                    {imageUrl === "" ?
                                        null
                                        :
                                        <Image src={imageUrl.imageUrl} style={{ width: '30vh', height: '30vh' }} />
                                    }
                                </Row>
                            </Col>
                            <Col md={12} style={{ marginTop: 50, textAlign: "center" }}>
                                <Button
                                    size="md"
                                    onClick={this.handleBackWard}
                                    style={{ width: 150, borderColor: "#4e98b8",backgroundColor:"#4e98b8", borderRadius: "30px" }}
                                >
                                    <p style={{color:"white"}}>ย้อนกลับ</p>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <hr />

                    <h1 style={{ fontSize: '14px', color: "grey", marginBottom: "2vh", textAlign: "Left" }}>
                        ผลลัพธ์ทั้งหมด : {this.state.numberOfMedicine} รายการ       {resultText}
                    </h1>
                    {(datas && datas.length > 0) ?
                        <Row    >
                            {datas.map((item, index) => (
                                <Col data-aos="fade-up" key={index} md={4} lg={3} sm={12} xs={12} style={{ padding: 12 }}>
                                    <Card >
                                        {item.images.length > 0 ?
                                            <LazyLoadImage
                                                alt={item.brandName[0]}
                                                src={item.images[0]}
                                                style={{ width: '100%', height: 200, objectFit: "cover" }}
                                                effect="blur" />
                                            :
                                            <Card.Img variant="top" style={{ width: '100%', height: 200, objectFit: "cover" }} src={require("../../../assets/Drugs/no-image.jpg")} />
                                        }
                                        <Card.Body>
                                            <Card.Title style={{ fontSize: '16px', }}>{item.brandName}</Card.Title>
                                            <Card.Title style={{ fontSize: '16px', }}>สี : {item.colors.map((color, index) => { return item.colors.length > 1 ? (`${index + 1}.) ${color} `) : (`${color} `) })}</Card.Title>
                                            <Card.Title style={{ fontSize: '16px', }}>รูปทรง : {item.shape}</Card.Title>
                                            <Card.Footer style={{ textAlign: 'center' }}>
                                                <Link
                                                    to={`/wiki/medicine/${item._id}`}
                                                    className='button' style={{ borderRadius: 25, width: '100%', borderColor: "#4e98b8", color: "#4e98b8" }}
                                                >
                                                    รายละเอียดเพิ่มเติม
                      </Link>
                                            </Card.Footer>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        :
                        <div>
                            <h1 style={{ textAlign: "center", fontSize: '60wh', color: "#4e98b8" }}>"ไม่พบข้อมูลยาในระบบ"</h1>
                        </div>
                    }
                    <Row style={{ marginTop: 50 }}>
                        <Col lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }} sm={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                            <Pagination
                                numOfPages={numberOfPages}
                                currentPage={currentPage}
                                nextPage={this.nextPage}
                                previousPage={this.previousPage}
                                onChange={this.onChangeNumPage}
                            />
                        </Col>
                    </Row>
                </Container >
            );
        }
    }
}

const mapStateToProps = (state) => ({ pageReducer: state.pageReducer })

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setPage,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ListResult);