import React from "react";
import UserServie from "../../../shared/services/UserService";
import DatePicker from 'react-datepicker';
import { Row, Col, Card, Button } from "react-bootstrap";
import { Loading } from "../../../components";
import Moment from "moment";
import makeAnimated from 'react-select/lib/animated';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getUserAuth } from '../../../actions/UserAction';
import "react-datepicker/dist/react-datepicker.css";
import "./styled.css"

class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userAuth: {}
    };
  }

  componentDidMount() {
    const { userAuth } = this.props.userReducer;
    this.setState({ userAuth });
  }

  handleChange(date) {
    const birthDay = Moment(date).format('l');
    this.onChangeValue(birthDay, "birthDay")
  }

  updateProfile = async () => {
    const { userAuth } = this.state;
    this.setState({ loading: true });
    const status = await UserServie.updateProfile(userAuth);
    this.setState({ loading: false });
    if (status === 200) {
      this.props.history.replace("/profile")
      this.props.getUserAuth();
    } else {
      console.log(status);
    }
  };

  onChangeValue = (value, property) => {
    this.setState({
      userAuth: {
        ...this.state.userAuth,
        [property]: value
      }
    })
  }

  getBirthDay = (date) => {
    if (date) {
      const d = date.split("/");
      const newDate = new Date(parseInt(d[2]), parseInt(d[1]) - 1, parseInt(d[0]));
      return newDate;
    } else {
      return new Date()
    }
  }

  render() {
    const { userAuth, loading } = this.state;
    const gridLeft = { xl: 3, md: 3, sm: 5, xs: 5 }
    const gridRight = { xl: 9, md: 9, sm: 12, xs: 12 }
    if (loading) {
      return <Loading />;
    }
    return (
      <Row
        data-aos="fade-down"
        className="justify-content-md-center"
        style={{
          fontSize: 16,
          marginTop: 50
        }}>
        <Col xl={7} lg={10} md={10} xs={12}>
          <Card style={{ padding: 30, boxShadow: `0 2px 7px 0 rgba(128, 128, 128, 0.2), 0 3px 10px 0 rgba(128, 128, 128, 0.19)` }}>
            <Row>
              <Col>
                <h1
                  style={{
                    textAlign: "center",
                    fontSize: 30,
                    marginBottom: 50,
                    color: "#4e98b8"
                  }}
                >
                  แก้ไขข้อมูลส่วนตัว
                </h1>
              </Col>
            </Row>
            <Row style={{ fontSize: 16 }}>
              <Col xl={{ span: 8, offset: 2 }}>
                <Row>
                  <Col {...gridLeft}>
                    <p>เพศ :</p>
                  </Col>
                  <Col {...gridRight}>
                    <Select
                      components={makeAnimated()}
                      placeholder="เลือกเพศ"
                      value={userAuth.gender ? { value: userAuth.gender, label: userAuth.gender } : null}
                      options={[
                        { value: "ชาย", label: "ชาย" },
                        { value: "หญิง", label: "หญิง" }
                      ]}
                      onChange={(option) => this.onChangeValue(option.value, "gender")}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col {...gridLeft}>
                    <p>วันเกิด : </p>
                  </Col>
                  <Col {...gridRight}>
                    <div style={{ width: "100%" }}>
                      <DatePicker
                        className="input input-custom"
                        style={{ width: "100%" }}
                        showYearDropdown
                        scrollableYearDropdown
                        dateFormatCalendar="MMMM"
                        dateFormat="dd/MM/yyyy"
                        yearDropdownItemNumber={15}
                        selected={this.getBirthDay(userAuth.birthDay)}
                        onChange={this.handleChange.bind(this)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col {...gridLeft}>
                    <p>เบอร์โทร :</p>
                  </Col>
                  <Col {...gridRight}>
                    <input
                      className="input"
                      type="text"
                      maxLength="10"
                      style={{
                        width: "100%",
                        fontSize: 16
                      }}
                      value={userAuth.phoneNumber || ""}
                      onChange={event => this.onChangeValue(event.target.value, "phoneNumber")}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col {...gridLeft}>
                    <p>หมู่เลือด :</p>
                  </Col>
                  <Col {...gridRight}>
                    <Select
                      components={makeAnimated()}
                      placeholder="เลือกกรุ๊ปเลือด"
                      value={userAuth.bloodType ? { value: userAuth.bloodType, label: userAuth.bloodType } : null}
                      options={[
                        { value: "A", label: "A" },
                        { value: "B", label: "B" },
                        { value: "AB", label: "AB" },
                        { value: "O", label: "O" }
                      ]}
                      onChange={(option) => this.onChangeValue(option.value, "bloodType")}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col {...gridLeft}><p>โรคประจำตัว :</p></Col>
                  <Col {...gridRight}>
                    <textarea
                      className="input"
                      style={{
                        fontSize: 16,
                        minHeight: 80
                      }}
                      onChange={event => this.onChangeValue(event.target.value, "congenitalDisease")}
                      value={userAuth.congenitalDisease}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col {...gridLeft}>
                    <p>แพ้ยา :</p>
                  </Col>
                  <Col {...gridRight}>
                    <div  >
                      <textarea
                        className="input"
                        style={{
                          fontSize: 16,
                          minBlockSize: 80
                        }}
                        onChange={event => this.onChangeValue(event.target.value, "beAllergic")}
                        value={userAuth.beAllergic}
                      /></div>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col style={{ textAlign: "center" }}>
                    <Button
                      variant="danger"
                      style={{
                        color: "white",
                        fontSize: 14,
                        marginRight: 20,
                        borderRadius: "30px",
                        backgroundColor: "#4e98b8",
                        borderColor: "#4e98b8",
                        width: "100px",
                        height: "35px",
                        fontFamily: "'Kanit', sans-serif"
                      }}
                      onClick={() => this.props.history.goBack()}
                    >
                      ย้อนกลับ
                  </Button>
                    <Button
                      style={{
                        fontSize: 14,
                        color: "white",
                        borderRadius: "30px",
                        backgroundColor: "#4e98b8",
                        borderColor: "#4e98b8",
                        width: "100px",
                        height: "35px",
                        fontFamily: "'Kanit', sans-serif"
                      }}
                      onClick={this.updateProfile.bind(this)}
                    >
                      บันทึก
                  </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({ userReducer: state.userReducer })

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    getUserAuth,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
