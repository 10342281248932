import React from "react";
import Answer from "./Answer";
import Topic from "./Topic";

class QuestionDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      questionId: "",
      question: {}
    };
  }

  componentWillMount() {

    const id = this.props.match.params.id;
    id ? this.setState({ questionId: id }): this.props.history.goBack();
  }

  render() {
    return (
      <div className="container">
        <br />
        <Topic
          questionId={this.state.questionId}
          history={this.props.history}
        />
       
        {/* <hr style={{ backgroundColor: "grey" }} /> */}
        <Answer questionId={this.state.questionId} />
        <br />
      </div>
    );
  }
}
export default QuestionDetail;
