import React from "react";
// import Button from "@material-ui/core/Button";
import UserService from "../../shared/services/UserService";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { Container, Row, Col, Button } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import { Alert } from "../../components";
class Register extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      text: "",
      loading: false,
      isSucceeded: false,
      visibleError: false
    };
    this.validator = new SimpleReactValidator({
      validators: {
        match: {  // name the rule
          message: 'The Confirm Password confirmation does not match.',
          rule: (val, params) => {
            return val === params[0]
          },
          required: true  // optional
        }
      }
    });
  }

  onSignUp = async () => {
    if (this.validator.allValid()) {
      this.setState({ loading: true });
      const data = {
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        password: this.state.password,
        confirmPassword: this.state.confirmPassword
      };
      const res = await UserService.register(data);
      this.setState({ loading: false });
      if (res.message === "Register successful.") {
        this.nextPage(res.verifyId);
      } else if (res.message === "This email has already been used.") {
        window.scroll(0, 0)
        this.setState({
          visibleError: true,
          email: "",
          password: "",
          confirmPassword: ""
        });
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  };

  // handleDismiss = () => {
  //   this.setState({ visibleError: false });
  // };

  nextPage = (id) => {
    this.props.history.push({
      pathname: `/verify/${id}`,
      state: {
        message: `ระบบทำการส่งคำขอยืนยันการใช้งานไปที่ <a href="mailto:${this.state.email}?subject=feedback" "email me">${this.state.email}</a> ของคุณแล้ว โปรดทำการยืนยันอีเมลเพื่อเข้าใช้งานระบบ!`
      }
    })
  }
  render() {
    const { loading } = this.state;
    return (
      <Container>
        <Row data-aos="fade-up" className="justify-content-md-center">
          <Col md={6}>
            <Row >
              <Col style={{ textAlign: "center" }}>
                <h1
                  style={{
                    fontSize: 30,
                    marginTop: 10,
                    marginBottom: 20,

                  }}
                >
                  ลงทะเบียน
                </h1>
              </Col>
            </Row>
            <Row>
              <Alert
                style={{ margin: "auto", color: "red", textAlign: "center", }}
                show={this.state.visibleError}
                variant="danger"
                handleDismiss={this.handleDismiss}
                timeout={3000}
              >
                <strong>การสมัครสมาชิกล้มเหลว</strong> อีเมลนี้ถูกใช้งานแล้ว กรุณาตรวจสอบอีเมลใหม่อีกครั้ง!
                </Alert>
            </Row>
            <Row>
              <p style={{ color: "#4e98b8", }}>ชื่อ : </p>
              <input
                className="input"
                type="text"
                placeholder="Firstname"
                value={this.state.firstName}
                onChange={event =>
                  this.setState({ firstName: event.target.value })
                }
              />
              <small style={{ color: "red" }}>{this.validator.message('firstName', this.state.firstName, 'required')}</small>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <p style={{ color: "#4e98b8", }}>นามสกุล : </p>
              <input
                className="input"
                type="text"
                placeholder="Lastname"
                value={this.state.lastName}
                onChange={event =>
                  this.setState({ lastName: event.target.value })
                }
              />
              <small style={{ color: "red" }}>{this.validator.message('lastName', this.state.lastName, 'required')}</small>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <p style={{ color: "#4e98b8", }}>อีเมล : </p>
              <input
                className="input"
                type="text"
                placeholder="email@example.com"
                value={this.state.email}
                onChange={event =>
                  this.setState({ email: event.target.value })
                }

              />
              <small style={{ color: "red" }}>{this.validator.message('email', this.state.email, 'required|email')}</small>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <p style={{ color: "#4e98b8", }}>รหัสผ่าน : </p>
              <input
                className="input"
                type="password"
                placeholder="Password"
                maxLength={24}
                value={this.state.password}
                onChange={event =>
                  this.setState({ password: event.target.value })
                }
              />
              <small style={{ color: "red" }}>{this.validator.message('password', this.state.password, 'required|min:8|max:24')}</small>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <p style={{ color: "#4e98b8", }}>รหัสผ่าน(อีกครั้ง) : </p>
              <input
                className="input"
                type="password"
                placeholder="Confirm password"
                maxLength={24}
                value={this.state.confirmPassword}
                onChange={event =>
                  this.setState({ confirmPassword: event.target.value })
                }
              />
              <small style={{ color: "red" }}>{this.validator.message('confirmPassword', this.state.confirmPassword, `required|min:8|max:24|match:${this.state.password}`)}</small>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col>
                <Button
                  className="button  is-medium is-fullwidth is-rounded "
                  style={{
                    backgroundColor: "#4e98b8",
                    color: "white",

                    marginTop: 50,
                    marginBottom: 30
                  }}
                  onClick={this.onSignUp}
                >
                  {loading ?
                    <ClipLoader
                      sizeUnit={"px"}
                      size={25}
                      color={"#ffffff"}
                    /> :
                    <span>สมัครสมาชิก</span>
                  }
                </Button>

                <Link
                  to="/login"
                  className=" "
                  style={{
                    color: "#4e98b8",

                  }}
                >
                  เข้าสู่ระบบ
                  </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Register;
