import { all } from 'redux-saga/effects';
import UserSaga from "./UserSaga";
import PageSaga from "./PageSaga";


export default function* rootSaga() {
    yield all([
        UserSaga(),
        PageSaga(),
    ]);
}