import React from "react";
import UserService from "../../shared/services/UserService";
import { Loading } from "../../components";
import { Image, Row, Col, Card, Button, ListGroup } from "react-bootstrap";
import ModalMenu from './UploadImage';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getUserAuth } from '../../actions/UserAction';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      signedIn: false,
      userAuth: {},
      modalShow: false,
      imageUrl: ""
    };
  }

  editProfile = () => {
    this.props.history.push('/edit-profile');
  }

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  checkSize(file) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    return isLt2M;
  }

  onChangeImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (this.checkSize(file)) {
        this.getBase64(file, imageUrl => this.setState({
          imageUrl: imageUrl,
          modalShow: true
        }));
      } else {
        alert('Image must smaller than 2MB!');
      }
    }
  }

  handleHideModal = () => {
    this.setState({ modalShow: false });
  }

  onUpload = async (file) => {
    const { userAuth } = this.props.userReducer;
    this.setState({ loading: true });
    const status = await UserService.updateImageProfile(userAuth, file);
    if (status === 200) {
      this.props.getUserAuth();
      this.setState({ modalShow: false });
    }
    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;
    const { userAuth } = this.props.userReducer;
    const gridLeft = { xl: { span: 3, offset: 3 }, lg: 5, md: 5, sm: 5, xs: 5 }
    const gridRight = { xl: 6, lg: 6, md: 7, sm: 7, xs: 7 }
    if (!userAuth || loading) {
      return <Loading />;
    }

    return (
      <Row
        className="justify-content-md-center"
        data-aos="fade-down"
        style={{
          marginTop: 50,
          marginBottom: 20,
          borderRadius: '30px',

        }}>
        <Col xl={7} lg={10} md={10} xs={12}>
          <Card
            style={{
              borderRadius: 5,
              padding: 30,
              boxShadow: "0 2px 7px 0 rgba(128, 128, 128, 0.2), 0 3px 10px 0 rgba(128, 128, 128, 0.19)"
            }}
          >
            <Row style={{ textAlign: "center" }}>
              <Col>
                <label
                  className="custom-file-upload"
                  style={{
                    position: "relative",
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                  htmlFor="uploadImage"
                >
                  {userAuth && userAuth.imageUrl ?
                    <Image
                      style={{
                        width: "15vh",
                        height: "15vh"
                      }}
                      src={userAuth.imageUrl}
                      alt="img-user"
                      roundedCircle
                    />
                    :
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#4e98b8",
                        width: "15vh",
                        height: "15vh",
                        borderRadius: "50%"
                      }}
                    >
                      <span
                        style={{ color: "#ffffff", fontSize: "4vh" }}>
                        {userAuth.firstName[0].toUpperCase()}
                      </span>
                    </div>
                  }
                  <div
                    style={{
                      position: "absolute",
                      right: 4,
                      bottom: 1,
                      cursor: "pointer",
                      borderRadius: "50%",
                      textAlign: "center",
                      backgroundColor: "white",
                      borderWidth: ".75px .75px .75px .75px",
                      borderStyle: "solid",
                      borderColor: "#d2d2d2",
                      width: "3vh",
                      height: "3vh"
                    }}
                  >
                    <ion-icon
                      style={{
                        fontSize: "1.8vh",
                        color: "grey",
                        margin: "0.5vh"
                      }}
                      active
                      name="camera"
                    />
                  </div>
                  <input
                    type="file"
                    id="uploadImage"
                    style={{ display: "none" }}
                    onChange={this.onChangeImage}
                  />
                </label>
              </Col>
            </Row>

            <Row style={{ padding: 10, textAlign: "center" }}>
              <Col>
                <h1 style={{ fontSize: 30 }}>{userAuth.firstName + " " + userAuth.lastName}</h1>
                <h1 style={{ fontSize: 20, marginTop: 10 }}>{userAuth.email}</h1>
              </Col>
            </Row>
            <Row style={{ marginTop: 30 }}>
              <Col xl={{ span: 2, offset: 5 }} lg={{ span: 4, offset: 4 }}>
                <Button
                  style={{ width: "100%",borderRadius:"30px",backgroundColor:"#4e98b8",borderColor:"#4e98b8"}}
                  onClick={this.editProfile}
                >
                  <span style={{ fontSize: 16,color:"white" }}>
                    <ion-icon name="create" />
                    แก้ไข
                    </span>
                </Button>
              </Col>
            </Row>
            <ListGroup variant="flush" style={{ marginTop: 30, fontSize: 16 }}>
              <ListGroup.Item>
                <Row >
                  <Col {...gridLeft}>
                    <p>
                      เพศ :
                      </p>
                  </Col>
                  <Col {...gridRight}>
                    <p>
                      {userAuth.gender ? userAuth.gender : '-'}
                    </p>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col {...gridLeft}>
                    <p>
                      วันเกิด :
                      </p>
                  </Col>
                  <Col {...gridRight}>
                    <p>
                      {userAuth.birthDay ? userAuth.birthDay : '-'}
                    </p>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col {...gridLeft}>
                    <p>
                      เบอร์โทร :
                      </p>
                  </Col>
                  <Col {...gridRight}>
                    <p>
                      {userAuth.phoneNumber ? userAuth.phoneNumber : '-'}
                    </p>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col {...gridLeft}>
                    <p>
                      หมู่เลือด :
                      </p>
                  </Col>
                  <Col {...gridRight}>
                    <p>
                      {userAuth.bloodType ? userAuth.bloodType : '-'}
                    </p>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col {...gridLeft}>
                    <p>
                      โรคประจำตัว :
                      </p>
                  </Col>
                  <Col {...gridRight}>
                    <p>
                      {userAuth.congenitalDisease ? userAuth.congenitalDisease : '-'}
                    </p>
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col {...gridLeft}>
                    <p>
                      แพ้ยา :
                      </p>
                  </Col>
                  <Col {...gridRight}>
                    <p>
                      {userAuth.beAllergic ? userAuth.beAllergic : '-'}
                    </p>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
            <ModalMenu
              show={this.state.modalShow}
              imageUrl={this.state.imageUrl}
              handleHideModal={this.handleHideModal}
              onUpload={this.onUpload}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({ userReducer: state.userReducer })

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    getUserAuth,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Profile);