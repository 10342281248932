import React, { Component } from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap"

class Search extends Component {
    onKeyPress = (e) => {
        if (e.key === "Enter") {
            this.props.onKeyPress(e.target.value)
        }
    }
    render() {
        return (
            <div>
                <InputGroup className="mb-3">
                    <FormControl
                        aria-describedby="basic-addon2"
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        onKeyPress={this.onKeyPress}
                    />
                    <InputGroup.Append>
                        <Button style={{ backgroundColor: "#4e98b8", borderColor: "#4e98b8" }}
                            onClick={this.props.onSearch}>
                            <ion-icon
                                name="search"
                                style={{
                                    verticalAlign: "middle",
                                    fontSize: 15
                                }}
                            />
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </div>
        )
    }
}

export default Search;