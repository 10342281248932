import { ApiService } from './ApiService';
import UploadService from './UploadService';
const service = {};
const URL = "/upload/image/search"
const UPLOAD = "search"
service.getAll = async (page, result, search = "", type = "") => {
    const data = await ApiService.get(`/article/?page=${page}&result=${result}&search=${search}&type=${type}`)
    return data;
}

service.getData = async id => {
    const data = await ApiService.get(`/article/${id}`)
    return data;
}

service.uploadSearchImage = async (image) => {
    const data = await UploadService.uploadSearchImage(URL, image, UPLOAD);
    return data;
}
export default service;