import React from "react";

const CustomToggle = React.forwardRef (({onClick},ref)=>(
<div ref={ref} onClick={(e) => {
  e.preventDefault();
  onClick(e);
}}
  style={{sursor:"pointer"}}
>
  <ion-icon
  style={{fontSize:25}}
  name="more"
  />
</div>
));
export default CustomToggle