import React, { Component } from "react";
import * as DrugService from "../../../shared/services/DrugService";
import ColorService from "../../../shared/services/ColorService";
import { Loading } from "../../../components";
import { Row, Col, Image, Button } from "react-bootstrap";
import ImageGallery from 'react-image-gallery';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import './style.css';

class Wiki extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      viewerIsOpen: false,
      colors: [],
      drug: {},
    };
  }
  componentWillMount() {
    window.scroll(0, 0)
    this.getInfoDrugsById(this.props.match.params.id);
  };
  getInfoDrugsById = async id => {
    await this.setState({ loading: true });
    const drug = await DrugService.getInfoDrugsById(id);
    if (drug.images.length === 13) {
      drug.images.length = 7
    } else if (drug.images.length === 12) {
      drug.images.length = 6
    } else if (drug.images.length === 11) {
      drug.images.length = 6
    } else if (drug.images.length === 10) {
      drug.images.length = 5
    } else if (drug.images.length === 9) {
      drug.images.length = 4
    }
    const colors = await ColorService.getAllSubColor();
    this.setState({ drug, colors, loading: false });
  };
  onViewer = () => {
    this.setState({ viewerIsOpen: true });
  };

  closeViewer = () => {
    this.setState({ viewerIsOpen: false });
  };

  handleBackWard = () => {
    this.props.history.goBack()
  }

  getBackGroundColor = (colorName) => {
    const data = this.state.colors.find(item => item.colorName === colorName)
    const color = data.colorName === "white" ? 'black' : 'white';
    return (
      {
        width: 25,
        height: 25,
        borderRadius: 4,
        backgroundColor: data.hex,
        padding: 5,
        borderColor: color,
        borderWidth: "1px",
        borderStyle: "solid"
      }
    )
  }

  renderItem = (item) => (
    <Image src={item.original} style={{ width: "100%", height: "60%", objectFit: "contain" }} />
  )

  render() {
    const { loading, drug } = this.state;
    const grid = { lg: "auto", md: "auto", sm: "auto", xs: "auto" };
    if (loading) {
      return <Loading />;
    }
    return (

      <Row className="justify-content-md-cel" style={{ marginTop: "5%" }}>
        <Col xl={{ span: 8, offset: 2 }} lg={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }} >
          <div data-aos="fade-down" style={{ marginTop: 10, marginBottom: 10 }}>
            <Row>
              <Col xl={{ span: 6, offset: 3 }} lg={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }} style={{ textAlign: "center" }}>
                {drug.images.length > 0 ?
                  <ImageGallery
                    items={drug.images.map((image) => {
                      return {
                        original: image,
                        thumbnail: image
                      }
                    })}
                    renderItem={this.renderItem}
                    showThumbnails={drug.images.length > 1}
                    showBullets={true}
                    showIndex={true}
                    showPlayButton={false}
                    slideOnThumbnailOver={true}
                    lazyLoad={true}
                  />
                  : <Image src={require("../../../assets/Drugs/no-image.jpg")} style={{ width: "80%" }} />
                }
              </Col>
            </Row>
          </div>
          <div>


            <Accordion allowMultipleExpanded allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    ชื่อสามัญทางยา
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {drug.brandName.map((name, i) => {
                    return <p key={i}>{`${i + 1}.) ${name} `}</p>
                  })}
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    ชื่อการค้า
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {drug.genericName.map((name, i) => {
                    return <p key={i}>{`${i + 1}.) ${name} `}</p>
                  })}
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    ขนาดความแรง
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {drug.strength ? drug.strength : 'ไม่ทราบข้อมูล'}
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    บรรจุภัณฑ์
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {drug.packing ? drug.packing : 'ไม่ทราบข้อมูล'}
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    ลักษณะ
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p><b>รูปทรง: </b>{drug.shape}</p>
                  <Row style={{ marginTop: 5 }}>
                    <Col {...grid}><p><b>สี:</b></p></Col>
                    {drug.colors.map((color, i) => {
                      return (
                        <div key={i} style={{ marginLeft: 5 }}>
                          <div style={{ float: "left" }}>
                            <p>  {`${i + 1}.) ${color}  `} </p>
                          </div>
                          <p style={{ float: "left" }}>
                            <div style={this.getBackGroundColor(color, i)} />
                          </p>
                        </div>
                      )
                    })}
                  </Row>
                  <p style={{ marginTop: 5 }}><b>สัญลักษณ์ 1:</b> {drug.imprint1 ? drug.imprint1 : 'ไม่มี'}</p>
                  <p style={{ marginTop: 5 }}><b>สัญลักษณ์ 2:</b> {drug.imprint2 ? drug.imprint2 : 'ไม่มี'}</p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    เลขทะเบียนตำรับยา
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {drug.registration ? drug.registration : 'ไม่ทราบข้อมูล'}
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    ใบสำคัญ/เลขที่อนุญาต
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {drug.licenseNumber ? drug.licenseNumber : 'ไม่ทราบข้อมูล'}
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    บริษัทผู้รับอนุญาต
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {drug.licensee ? drug.licensee.companyName : 'ไม่ทราบข้อมูล'}
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    บริษัทผู้ผลิต
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {drug.manufacturer ? drug.manufacturer.companyName : 'ไม่ทราบข้อมูล'}
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    บริษัทผู้จำหน่าย
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {drug.distributer ? drug.distributer.companyName : 'ไม่ทราบข้อมูล'}
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    ชนิดของยาควบคุมตามกฏหมาย
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {drug.category ? drug.category : 'ไม่ทราบข้อมูล'}
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    ประเภทยา
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {drug.type ? drug.type : 'ไม่ทราบข้อมูล'}
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    ข้อบ่งใช้
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {drug.uses ? drug.uses : 'ไม่ทราบข้อมูล'}
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    ขนาดที่ให้
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {drug.dosage ? drug.dosage : 'ไม่ทราบข้อมูล'}
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    คำแนะนำ
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ whiteSpace: "pre-line" }}>
                    {drug.advice ? drug.advice : 'ไม่ทราบข้อมูล'}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    ข้อควรระวัง
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ whiteSpace: "pre-line" }}>
                    {drug.contraindications ? drug.contraindications : 'ไม่ทราบข้อมูล'}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    คำเตือน
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ whiteSpace: "pre-line" }}>
                    {drug.specialPrecautions ? drug.specialPrecautions : 'ไม่ทราบข้อมูล'}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    อาการไม่พึงประสงค์
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>{drug.adverseReactions ? drug.adverseReactions : 'ไม่ทราบข้อมูล'}</p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    อันตรกิริยาระหว่างยา
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p style={{ whiteSpace: "pre-line" }}>
                    {drug.interactions ? drug.interactions : 'ไม่ทราบขอมูล'}
                  </p>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    รูปแบบ
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {drug.form ? drug.form : 'ไม่ทราบข้อมูล'}
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    ระดับความปลอดภัยของยาที่ใช้ในหญิงมีครรภ์
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {drug.pregSafety ? drug.pregSafety : 'ไม่ทราบข้อมูล'}
                </AccordionItemPanel>
              </AccordionItem>

            </Accordion>

          </div>

          <Row>
            <Col xl={{ span: 6, offset: 3 }} lg={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }} style={{ textAlign: "center" }}>
              <Button
                size="md"
                onClick={this.handleBackWard}
                style={{ marginTop: "5%", width: 150, borderColor: "#4e98b8", backgroundColor: "#4e98b8", borderRadius: "30px" }}
              >
                <p style={{ color: "white" }}>ย้อนกลับ</p>
              </Button>
            </Col>
          </Row>

        </Col >
      </Row >
    )
  }
}

export default Wiki;
