import { ApiService } from './ApiService';
const service = {};

service.getAllMainColor = async () => {
   const res = await ApiService.get("/main-color")
   return res.data
}

service.getAllSubColor = async () => {
    const res = await ApiService.get("/sub-color")
    return res.data
 }

export default service;