const service = {};
const USER_KEY = "auth-user-clinicya";
const TOKEN_KEY = "auth-token-clinicya";
const user = JSON.stringify({
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    role: "visitor",
    authenticated: false
});

service.onSignIn = async access_token => {
    try {
        await localStorage.setItem(TOKEN_KEY, JSON.stringify(access_token));
    } catch (error) {
        console.log(error);
    }
};

service.onSignOut = async () => {
    try {
        await localStorage.removeItem(USER_KEY);
        await localStorage.removeItem(TOKEN_KEY);
        const user = localStorage.getItem(USER_KEY);
        return user
    } catch (error) {
        console.log(error);
    }
};

service.setUserAuth = user => {
    try {
        user.authenticated = true;
        localStorage.setItem(USER_KEY, JSON.stringify(user));
    } catch (error) {
        console.log(error);
    }
};
service.isSignedIn = () => {
    try {
        const res = localStorage.getItem(USER_KEY);
        return res !== null
            ? true
            : false;
    } catch (error) {
        console.log(error);
    }
};

service.getUserAuth = () => {
    try {
        const res = localStorage.getItem(USER_KEY) || user;
        return JSON.parse(res);
    } catch (error) {
        console.log(error);
    }
};

service.getToken = () => {
    try {
        const res = localStorage.getItem(TOKEN_KEY);
        return JSON.parse(res);
    } catch (error) {
        console.log(error);
    }
};

service.updateImage = () => {
    try {
        const res = localStorage.getItem(TOKEN_KEY);
        return JSON.parse(res);
    } catch (error) {
        console.log(error);
    }
};

export default service;
