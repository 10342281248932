import { ApiService } from "./ApiService";
const service = {};
service.getTopic = async (page, result, search, category = "") => {
  const res = await ApiService.get(`/question/all/?page=${page}&result=${result}&search=${search}&category=${category}`);
  return res.data;
};

service.getQuestionById = async id => {
  const res = await ApiService.get(`/question/${id}`);
  return res.data;
};

service.getQuestionByUserId = async (page, result, search) => {
  const res = await ApiService.get(`/question/user/?page=${page}&result=${result}&search=${search}`);
  return res.data;
}

service.getAnswerByQuestionId = async id => {
  const res = await ApiService.get(`/answer/question/${id}`);
  return res.data;
};

service.getCategory = () => {
  return categories;
}

service.addQuestion = async infoArray => {
  const res = await ApiService.post("/question", infoArray)
  return res.status;
};

service.addAnswers = async data => {
  const res = await ApiService.post("/answer", data);
  return res.status;
};

service.updateQuestion = async (data) => {
  const res = await ApiService.put(`/question/${data._id}`, data);
  return res.status;
};

service.updateAnswer = async (id, data) => {
  const res = await ApiService.put(`/answer/${id}`, data);
  return res.status;
};

service.deleteQution = async id => {
  const res = await ApiService.delete(`/question/${id}`);
  return res.status;
};

service.deleteAnswer = async id => {
  const res = await ApiService.delete(`/answer/${id}`);
  return res.status;
};

const categories = [
  {_id: 1, category: "ข้อมูลยา/เภสัชภัณฑ์ยา"},
  {_id: 2, category: "ข้อมูลโรค/อาการป่วย"},
  {_id: 3, category: "เภสัชกรรมคลินิค"},
  {_id: 4, category: "ข้อมูลอื่นๆ"}
]

export default service;