import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Loading, Search } from "../../../components";
import AuthGuardService from "../../../shared/services/AuthGuardService";
import TopicService from "../../../shared/services/TopicService";
import QuestionList from "../QuestionList";
class UserQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            currentPage: 1,
            numberOfDatas: 0,
            result: 10,
            datas: [],
            search: "",
            markSearch: ""
        };
    }

    componentWillMount() {
        this.getTopic();
        this.checkSignedIn();
    }

    checkSignedIn = async () => {
        await this.setState({ loading: true });
        const signedIn = await AuthGuardService.isSignedIn();
        await this.setState({ signedIn, loading: false });
    };

    newTopic = () => {
        const { signedIn } = this.state
        if (signedIn) {
            this
                .props
                .history
                .push('/add-question')
        } else {
            this
                .props
                .history
                .push('/login')
        }
    }

    getTopic = async () => {
        const { currentPage, result, search } = this.state;
        this.setState({ loading: true });
        const data = await TopicService.getTopic(currentPage - 1, result, search);
        this.setState({ datas: data.datas, numberOfDatas: data.numberOfDatas, markSearch: search, loading: false })
        window.scroll(0, 0);
    };

    onChangePage = (value) => {
        this.setState({ currentPage: value });
        this.getTopic();
    }

    onChange = async (e) => {
        await this.setState({ search: e.target.value });
    }

    onSearch = () => {
        this.getTopic();
    }

    handleKeyPress = (value) => {
        this.setState({ search: value })
        this.getTopic();
    }

    render() {
        const {
            datas,
            loading,
            currentPage,
            numberOfDatas,
            search,
            markSearch,
            result
        } = this.state;
        if (loading) {
            return <Loading />
        }
        return (
            <Row>
                <Col
                    xl={{ span: 8, offset: 2 }}
                    md={{ span: 10, offset: 1 }}
                >
                    <Row>
                        <Col
                            md={3}
                            style={{
                                marginTop: 10,
                                marginBottom: 10
                            }}>
                            <Button

                                style={{
                                    fontSize: 14,
                                    backgroundColor: "#4e98b8",
                                    borderColor: "#4e98b8",
                                    fontFamily: "'Kanit', sans-serif"
                                }}
                                onClick={this.newTopic}>
                                <ion-icon
                                    name="add-circle-outline"
                                    style={{ marginRight: 10 }}
                                />
                                ตั้งคำถาม
                        </Button>
                        </Col>
                        <Col
                            xl={{ span: 3, offset: 6 }}
                            md={{ span: 4, offset: 5 }}
                            style={{
                                marginTop: 10,
                                marginBottom: 10
                            }}>

                            <Search
                                placeholder="ค้นหาคำถาม"
                                value={search}
                                onChange={this.onChange}
                                onSearch={this.onSearch}
                                onKeyPress={this.handleKeyPress} />

                        </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                        <Col
                        >
                            <QuestionList
                                datas={datas}
                                markSearch={markSearch}
                                currentPage={currentPage}
                                numberOfDatas={numberOfDatas}
                                result={result}
                                onChangePage={this.onChangePage}
                                history={this.props.history}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default UserQuestion;
