import React from 'react';
import { Button } from "react-bootstrap";
import './styleScroll.css'

class ScrollTop extends React.Component {
  constructor() {
    super();

    this.state = {
      intervalId: 0,
      status: false
    };
  }

  scrollStep = async () => {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, 0);
  }

  scrollToTop = () => {
    let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
    this.setState({ intervalId: intervalId });
  }

  render() {
    return (
      <div className="long" style={{ zIndex: 999999 }}>
        {/* <ScrollButton scrollStepInPx="50" delayInMs="16.66" /> */}
        <Button title='Back to top' className='scroll' onClick={this.scrollToTop}>
          <ion-icon
            style={{
              color: "white",
              fontSize: 25
            }}
            name="arrow-dropup" />
        </Button>
      </div>)
  }
}
export default ScrollTop;
