import React from "react";
import TopicService from "../../../../shared/services/TopicService";
// import { Button } from "@material-ui/core";
import authGuardService from "../../../../shared/services/AuthGuardService";
import { Row, Col, Button } from "react-bootstrap";
import { Loading, Can } from "../../../../components";

class AddAnswer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      questionId: "",
      question: {},
      text: ""
    };
  }

  componentWillMount() {
    this.getData();
  }

  getData = async () => {
    await this.setState({ loading: true });
    const questionId = this.props.questionId;
    const userAuth = await authGuardService.getUserAuth();
    const question = await TopicService.getQuestionById(questionId);
    await this.setState({ userAuth, question, questionId, loading: false });
  };

  onSubmit = async () => {
    const { text } = this.state;
    if (text.trim()) {
      this.props.onSubmit(this.state.text)
    }
  };

  render() {
    const { userAuth, question, loading } = this.state;
    if (loading) {
      return <Loading />;
    }
    return (
      <Can
        role={userAuth.role}
        perform="posts:create"
        data={{
          userId: userAuth._id,
          postOwnerId: question.questioner._id
        }}
        yes={() => (
          <Row className="justify-content-md-center">
            <Col>
              <p style={{ marginTop: 20 }}>แสดงความคิดเห็น</p>
              <Row>
                <Col style={{ padding: 15 }}>
                  <textarea
                    className="textarea"
                    placeholder="เขียนความคิดเห็น"
                    type="text"
                    style={{ marginTop: 10 }}
                    value={this.state.text}
                    onChange={event => this.setState({ text: event.target.value })}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={{ span: 2, offset: 10 }}>
                  <Button
                    className="button is-primary"
                    onClick={this.onSubmit}
                    style={{ fontSize: 15, width: "100%", borderRadius: "30px",backgroundColor:"#4e98b8",fontFamily: "'Kanit', sans-serif" }}
                  >
                    ส่งข้อความ
              </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        no={() => null}
      />
    )
  }
}

export default AddAnswer;