import React from 'react'
import ArticleService from '../../shared/services/ArticleService';
import { Card, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { Pagination, Loading } from "../../components";
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import typeArticle from '../../shared/services/TypeArticle';

class ArticleNews extends React.Component {
  constructor() {
    super();
    this.state = {
      datas: [],
      loading: false,
      search: "",
      currentPage: 1,
      result: 8,
      numberOfPages: 0,
      numberOfDatas: 0,
      type: "",
      articleType: []
    }
  }

  componentWillMount = () => {
    this.dataPage()
  }

  dataPage = async () => {
    const { search, currentPage, result, type } = this.state
    this.setState({ loading: true })
    const types = await typeArticle.getTypeArticle()
    const data = await ArticleService.getAll(currentPage - 1, result, search, type.value);
    await this.setState({
      datas: data.data.datas,
      numberOfDatas: data.data.numberOfDatas,
      numberOfPages: Math.ceil(data.data.numberOfDatas / result),
      articleType: types,
      loading: false
    });
  }

  onChange = async (e) => {
    await this.setState({ search: e.target.value });
  }

  onSearch = async () => {
    if (this.state.search.trim()) {
      await this.setState({ currentPage: 1 })
      this.dataPage();
    } else {
      await this.setState({ currentPage: 1 })
      this.dataPage();
    }

  }

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.setState({ search: e.target.value })
      this.dataPage();
    }
  }


  onSelectArticle = async (typeA) => {
    if (typeA) {
      await this.setState({ type: typeA, currentPage: 1 })
      this.dataPage()
    } else {
      await this.setState({ type: "", currentPage: 1 })
      this.dataPage()

    }

  }

  nextPage = async (value) => {
    await this.setState({ currentPage: value })
    this.dataPage()

  }

  previousPage = async (value) => {
    await this.setState({ currentPage: value })
    this.dataPage()

  }

  onChangeNumPage = async (value) => {

    await this.setState({ currentPage: value })
    this.dataPage()

  }

  render() {
    const { loading, datas, search, numberOfPages, currentPage, numberOfDatas, articleType, type } = this.state
    if (loading) {
      return <Loading />
    }
    return (
      <div data-aos="fade-up" >
        <h1 style={{ fontSize: '3.5vh', textAlign: "center", padding: 30, color: "#159EA3", }} >บทความ</h1>

        <Row className="justify-content-md-center">
          <Col md={10}>
            <Row className="justify-content-md-end">
              <Col md={3} style={{ marginTop: 10, marginBottom: 10 }}>
                <Select
                  isClearable
                  components={makeAnimated()}
                  styles={{ control: (provided) => ({ ...provided, fontSize: 14, borderRadius: 25 }) }}
                  placeholder={"เลือกประเภทบทความ"}
                  options={articleType.map((item) => {
                    return { value: item.type, label: item.type }
                  })}
                  value={Object.keys(articleType).length > 0 ? type : null}
                  onChange={this.onSelectArticle}
                />
              </Col>
              <Col md={3} style={{ marginTop: 10, marginBottom: 10 }}>
                <input
                  className="input-custom"
                  style={{
                    width: "85%",
                    lineHeight: "30px",
                    borderStyle: "solid",
                    borderWidth: ".75px 0px .75px .75px",
                    borderColor: "#d2d2d2",
                    borderTopLeftRadius: 25,
                    borderBottomLeftRadius: 25,
                    padding: 2,
                    paddingLeft: 10,
                    outline: "none"
                  }}
                  placeholder="ค้นหาบทความ"
                  value={search}
                  onKeyPress={this.handleKeyPress}
                  onChange={this.onChange}
                />
                <button
                  className="input-custom"
                  style={{
                    borderTopRightRadius: 25,
                    borderBottomRightRadius: 25,
                    borderStyle: "solid",
                    borderWidth: ".75px .75px .75px 0px",
                    borderColor: "#d2d2d2",
                    backgroundColor: "#159EA3",
                    lineHeight: "30px",
                    width: "15%",
                    padding: 2,
                    outline: "none"
                  }}
                  onClick={this.onSearch}
                >
                  <ion-icon name="search" style={{ color: "#ffffff", verticalAlign: "middle", }} />
                </button>
              </Col>
            </Row>
            <hr />
            <Row>
              {numberOfDatas > 0 ? datas.map((item, index) =>
                (
                  <Col data-aos="fade-up" key={index} md={4} lg={3} sm={12} xs={12} style={{ padding: 12, }}>
                    <Card >
                      {item.imageUrl ? <Card.Img variant="top" style={{ width: '100%', height: 190 }} src={item.imageUrl} /> :
                        <Card.Img variant="top" style={{ width: '100%', height: 190 }} src={require('../../assets/Drugs/default.png')} />}
                      <Card.Body >
                        {(item.topic.length < 25) ?
                          <Card.Title style={{ fontSize: '18px', color: "#159EA3" }}>{item.topic}</Card.Title>
                          : <Card.Title style={{ fontSize: '18px', color: "#159EA3" }} >
                            {item.topic.substr(0, 25) + "..."}
                          </Card.Title>
                        }
                        <Card.Text style={{ fontSize: '14px' }}>
                          {`ประเภท : ${item.type}`}
                        </Card.Text>
                        <br />
                        <Card.Footer style={{ textAlign: 'center' }}>
                          <Link
                            to={`/article/${item._id}/${item.type}`}
                            className='button is-info' variant="info" style={{ borderRadius: 25, width: '100%' }}
                          >
                            อ่าน
                        </Link>
                        </Card.Footer>
                      </Card.Body>
                    </Card>
                  </Col>
                ))

                :
                <Col
                  style={{
                    fontSize: "16px",
                    textAlign: "center"
                  }}>
                  "ไม่พบบทความในระบบ..."
                   </Col>
              }
            </Row>

            {numberOfDatas > 0 ?
              <Row style={{ marginTop: 50 }}>
                <Col lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }} sm={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                  <Pagination
                    numOfPages={numberOfPages}
                    currentPage={currentPage}
                    nextPage={this.nextPage}
                    previousPage={this.previousPage}
                    onChange={this.onChangeNumPage}
                  />
                </Col>
              </Row>
              : null
            }
            <div style={{ marginTop: 10 }}>
              <h1 style={{ fontSize: '14px', color: "grey", marginBottom: "2vh", textAlign: "center" }}>
                ผลลัพธ์ทั้งหมด : {this.state.numberOfDatas} รายการ
            </h1>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default ArticleNews;