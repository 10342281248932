import React from "react";
import { BeatLoader } from "react-spinners";
import { Row , Col } from 'react-bootstrap'
class Loading extends React.Component {
  render() {
    return (
      <Row className="justify-content-md-center" >
        <Col style={{ display:"flex" , justifyContent : "center",alignItems:"center", height: window.innerHeight }} >
          <BeatLoader
            loading
            sizeUnit={"px"}
            size={22}
            color={"#4e98b8"}
          />
        </Col>
      </Row>

    );
  }

};

export default Loading;
