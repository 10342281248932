import {
    GET_USER_AUTH_REQUEST,
    GET_USER_AUTH_SUCCESS,
    GET_USER_AUTH_FAILURE,
    RESET_USER_AUTH_REQUEST,
    RESET_USER_AUTH_SUCCESS,
    RESET_USER_AUTH_FAILURE,
} from "../actions/UserAction/types";

const INITIAL_STATE = {
    userAuth: null
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_USER_AUTH_REQUEST:
            return { ...state };
        case GET_USER_AUTH_SUCCESS:
            return { ...state, userAuth: action.json }
        case GET_USER_AUTH_FAILURE:
            return { ...state, errorMessage: action.json }
        case RESET_USER_AUTH_REQUEST:
            return { ...state };
        case RESET_USER_AUTH_SUCCESS:
            return { ...state, userAuth: action.json }
        case RESET_USER_AUTH_FAILURE:
            return { ...state, errorMessage: action.json }
        default:
            return state;
    }
};
export default userReducer;