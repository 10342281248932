import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import TopicService from "../../../shared/services/TopicService";
import QuestionList from "../QuestionList";
import { Search, Loading } from "../../../components";
import makeAnimated from 'react-select/lib/animated';
import Select from 'react-select';
class PharmQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            categories: [],
            category: {},
            currentPage: 1,
            numberOfDatas: 0,
            result: 10,
            datas: [],
            search: "",
            markSearch: ""
        };
    }

    componentWillMount() {
        this.getCategory();
        this.getTopic();
    }

    getCategory = async () => {
        const categories = await TopicService.getCategory();
        this.setState({ categories });
    }

    getTopic = async () => {
        const { currentPage, result, search, category } = this.state;
        this.setState({ loading: true });
        const data = await TopicService.getTopic(currentPage - 1, result, search, category.label || "");
        this.setState({ datas: data.datas, numberOfDatas: data.numberOfDatas, markSearch: search, loading: false })
        window.scroll(0, 0);
    };

    onSelectCategory = async (category) => {
        if (category) {
            await this.setState({ category: category });
        } else {
            await this.setState({ category: {} });
        }
        this.getTopic();
    }

    onChangePage = (value) => {
        this.setState({ currentPage: value });
        this.getTopic();
    }

    onChange = async (e) => {
        await this.setState({ search: e.target.value });
    }

    onSearch = () => {
        this.getTopic();
    }

    handleKeyPress = (value) => {
        this.setState({ search: value })
        this.getTopic();
    }

    render() {
        const {
            datas,
            loading,
            currentPage,
            numberOfDatas,
            search,
            markSearch,
            result,
            category,
            categories
        } = this.state;

        if (loading) {
            return <Loading />
        }
        return (
            <Row>
                <Col
                    xl={{ span: 8, offset: 2 }}
                    md={{ span: 10, offset: 1 }}
                >
                    <Row>
                        <Col
                            lg={{ span: 3, offset: 6 }}
                            md={{ span: 4, offset: 4 }}
                            style={{
                                marginTop: 10,
                                marginBottom: 10
                            }}>
                            <Select
                                isClearable
                                styles={{ control: (provided) => ({ ...provided, fontSize: 14, borderRadius: 25 }) }}
                                components={makeAnimated()}
                                placeholder="เลือกประเภทคำถาม"
                                value={Object.keys(category).length > 0 ? category : null}
                                options={categories.map((item) => {
                                    return { value: item._id, label: item.category }
                                })}
                                onChange={this.onSelectCategory}
                            />
                        </Col>
                        <Col
                            lg={{ span: 3, offset: 0 }}
                            md={{ span: 4, offset: 0 }}
                            style={{
                                marginTop: 10,
                                marginBottom: 10
                            }}>
                            <Search
                                placeholder="ค้นหาด้วย ID หรือ หัวข้อเรื่อง"
                                value={search}
                                onChange={this.onChange}
                                onSearch={this.onSearch}
                                onKeyPress={this.handleKeyPress} />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                        <Col>
                            <QuestionList
                                datas={datas}
                                markSearch={markSearch}
                                currentPage={currentPage}
                                numberOfDatas={numberOfDatas}
                                result={result}
                                onChangePage={this.onChangePage}
                                history={this.props.history} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default PharmQuestion;
