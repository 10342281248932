import React from "react";
// import Button from "@material-ui/core/Button";
import { Container, Row, Col, Button } from "react-bootstrap";
import UserService from "../../../shared/services/UserService"
import { ClipLoader } from "react-spinners";
import Alert from "../../../components/Alert";
import Joi from "@hapi/joi"

class NewPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      showMessage: false,
      errorMessage: "",
      user: {},
      password: "",
      confirmPassword: ""
    }
  }
  componentWillMount() {
    const user = this.props.location.state ? this.props.location.state.user : null;
    user ? this.setState({ user: user }) : this.props.history.goBack();
  }

  checkValidator = async () => {
    var schema = Joi.object().keys({
      password: Joi.string()
        .min(8)
        .max(24)
        .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/),
      confirmPassword: Joi.string()
        .min(8)
        .max(24)
        .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/)
        .required().valid(Joi.ref("password"))
    });
    try {
      await schema.validateAsync({ password: this.state.password, confirmPassword: this.state.confirmPassword });
      this.handleSubmit()
    } catch (e) {
      this.Failed(e)
    }
  }

  handleSubmit = async () => {
    const data = {
      verifyId: this.state.user.verifyId,
      password: this.state.password
    }
    const status = await UserService.resetPassword(this.state.user._id, data)
    status.status === 200 ? this.props.history.replace("/login") : console.log(status);
  }

  Failed = (message) => {
    if (message.message === "\"password\" is not allowed to be empty") {
      this.setState({ errorMessage: "กรุณากรอก รหัสผ่านใหม่" })
    } else if (message.message === "\"password\" length must be at least 8 characters long") {
      this.setState({ errorMessage: "กรุณากรอก รหัสผ่านใหม่ที่มีความยาวไม่ต่ำกว่า 8 ตัวอักษร" })
    } else if (message.message === "\"password\" length must be less than or equal to 24 characters long") {
      this.setState({ errorMessage: "กรุณากรอก รหัสผ่านใหม่ที่มีความยาวไม่มากกว่า 24 ตัวอักษร" })
    } else if (message.message === "\"confirmPassword\" must be [ref:password]") {
      this.setState({ errorMessage: "กรุณากรอก รหัสผ่านให้ตรงกัน" })
    } else {
      this.setState({ errorMessage: "กรุณากรอก รหัสผ่านที่ประกอบด้วย อักษรพิมพ์เล็ก, พิมพ์ใหญ่ และตัวเลขอย่างน้อย 1 ตัว" })
    }
    this.setState({
      showMessage: true,
      email: "",
      loading: false
    });
  }

  handleDismiss = () => {
    this.setState({ showMessage: false });
  };

  render() {
    const { loading, errorMessage } = this.state
    return (
      <Container>
        <Row data-aos="fade-up" className="justify-content-md-center">
          <Col md={6}>
            <Row >
              <Col style={{ textAlign: "center" }}>
                <h1
                  style={{
                    fontSize: 30,
                    marginTop: 10,
                    marginBottom: 20,

                  }}
                >
                  สร้างรหัสผ่านใหม่
                </h1>
                <p>สร้างรหัสผ่านใหม่ที่มีความยาวไม่ต่ำกว่า 8 ตัวอักษรประกอบด้วย อักษรพิมพ์เล็ก, พิมพ์ใหญ่ และตัวเลขอย่างน้อย 1 ตัว
                เช่น A1clinicya เป็นต้น
                </p>
                <Alert
                  style={{ margin: "auto", color: "red", textAlign: "center", }}
                  show={this.state.showMessage}
                  variant="danger"
                  handleDismiss={this.handleDismiss}
                >
                  {errorMessage}
                </Alert>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <p style={{ color: "#4e98b8", }}>รหัสผ่านใหม่ : </p>
              <input
                className="input"
                type="Password"
                value={this.state.password}
                placeholder="password"
                onChange={event => this.setState({ password: event.target.value })}
              />
            </Row>
            <Row style={{ marginTop: 20 }}>
              <p style={{ color: "#4e98b8", }}>รหัสผ่านใหม่อีกครั้ง : </p>
              <input
                className="input"
                type="Password"
                value={this.state.confirmPassword}
                placeholder="ConfirmPassword"
                onChange={event => this.setState({ confirmPassword: event.target.value })}
              />
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col>
                <Button
                  className="button  is-medium is-fullwidth is-rounded "
                  style={{
                    backgroundColor: "#4e98b8",
                    color: "white",

                    marginTop: 20,
                    marginBottom: 30
                  }}
                  onClick={this.checkValidator}
                >{loading ?
                  <ClipLoader
                    sizeUnit={"px"}
                    size={25}
                    color={"#ffffff"}
                  />
                  : <span>ยืนยัน</span>
                  }
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default NewPassword;