import { ApiService } from "./ApiService";
const service = {};

service.uploadSearchImage = async (url, image, upload) => {
    const headers = {
        Accept: "application/json",
        "content-type": "multipart/form-data"
    };
    const formData = new FormData();
    formData.append("image", image)
    const res = await ApiService.post(`${url}/?type=${upload}`, formData, headers);
    return res.data
};
export default service;
