import React, { Component } from "react";
import UserService from "../../shared/services/UserService";
import { Link } from "react-router-dom";
import { Alert } from "../../components";
import { ClipLoader } from "react-spinners";
import { Container, Row, Col, Button } from "react-bootstrap";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getUserAuth } from '../../actions/UserAction';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      password: "",
      showMessage: false,
      errorMessage: ""
    };
  }

  verify(user) {
    this.props.history.push({
      pathname: `/verify/${user.verifyId}`,
      state: {
        message: `ระบบทำการส่งคำขอยืนยันการใช้งานไปที่ <a href="mailto:${this.state.email}?subject=feedback" "email me">${this.state.email}</a> ของคุณแล้ว โปรดทำการยืนยันอีเมลเพื่อเข้าใช้งานระบบ!`
      }
    })
  }

  signInSuccess = () => {
    this.props.getUserAuth();
    this.props.history.replace("/");
  }

  signInFailed = (message) => {
    this.setState({
      showMessage: true,
      email: "",
      password: "",
      errorMessage: message,
      loading: false
    });
  }

  onSignIn = async () => {
    this.setState({ loading: true });
    const { email, password } = this.state;
    const account = { email, password };
    const res = await UserService.signIn(account);
    if (res.message === "Login successful") {
      this.signInSuccess();
    } else if (res.message === "Email not verified") {
      this.verify(res.user);
    } else {
      this.signInFailed(res.message);
    }
    this.setState({ loading: false });
  };

  handleDismiss = () => {
    this.setState({ showMessage: false });
  };

  render() {
    const { loading } = this.state;
    return (
      <Container>
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <img
            style={{ height: '10vh' }}
            src={require("../../assets/Bg/Logo3.png")}
            alt="logo app"
          />
          <h1
            style={{
              fontSize: 30,
              textAlign: "center",
              marginTop: 10,
              marginBottom: 20,

            }}
          >
            เข้าสู่ระบบ
        </h1>
        </div>
        <Row data-aos="fade-up" className="justify-content-md-center">
          <Col md={6}>
            <Alert
              style={{ margin: "auto", color: "red", textAlign: "center", }}
              show={this.state.showMessage}
              variant="danger"
              handleDismiss={this.handleDismiss}
              timeout={3000}
            >
              <strong>การเข้าสู่ระบบล้มเหลว</strong> โปรดตรวจสอบข้อมูลใหม่อีกครั้ง!
            </Alert>
            <p style={{ color: "#4e98b8", }}>อีเมล : </p>
            <input
              className="input"
              type="text"
              value={this.state.email}
              placeholder="email@example.com"
              name="emailid"
              onChange={event =>
                this.setState({ email: event.target.value })
              }
            />
            <div style={{ margin: 20 }} />
            <p style={{ color: "#4e98b8", }}>รหัสผ่าน : </p>
            <input
              className="input"
              type="Password"
              value={this.state.password}
              placeholder="********"
              onChange={event =>
                this.setState({ password: event.target.value })
              }
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  this.onSignIn()
                }
              }}
            />

            <Button
              className="button is-medium is-fullwidth is-rounded "
              style={{
                backgroundColor: "#4e98b8",
                color: "white",

                marginTop: 40,
                // marginBottom: 0
              }}
              onClick={this.onSignIn}
            >
              {loading ?
                <ClipLoader
                  sizeUnit={"px"}
                  size={25}
                  color={"#ffffff"}
                />
                : <span>เข้าสู่ระบบ</span>
              }
            </Button>
            <Row>
              <Col>
                <h1>
                  <Link
                    to="/register"
                    className=" is-inverted is-outlined "
                    style={{
                      backgroundColor: "white",
                      color: "#4e98b8",
                      fontSize: 14,
                    }}
                  >
                    สมัครสมาชิก
                    </Link>
                </h1>
              </Col>
              <Col>
                <h1 style={{ float: "Right" }}>
                  <Link
                    to="/ResetPassword"
                    className=" is-inverted is-outlined "
                    style={{
                      backgroundColor: "white",
                      color: "#4e98b8",
                      fontSize: 14,
                    }}
                  >
                    ลืมรหัสผ่านหรือไม่?
                    </Link>
                </h1>
              </Col>
            </Row>
          </Col>
        </Row>



      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    getUserAuth,
  }, dispatch)
);

export default connect(null, mapDispatchToProps)(Login);
