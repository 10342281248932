import React, { Component } from "react";
import MapApi from "./Map";
import Detail from "./Detail";
import {Loading} from "../../components";
import {  Card, Row, Col } from "react-bootstrap";

class Contact extends Component {
  constructor() {
    super();
    this.state = {
      load: false
    };
  }
  render() {
    const { loading } = this.state;
    if (loading) {
      return <Loading />;
    }
    return (
      <Row className="justify-content-md-center" style={{  }}>
        <Col  md={10}>
          <h1 style={{ fontWeight: 500, fontSize: 30, textAlign: "center", padding: 30, color: "#159EA3" }}>
            ติดต่อเรา
        </h1>

          <Card data-aos="fade-down" style={{ padding: 20, marginBottom: 100, boxShadow: `0px 0px 15px grey` }}>
            <Row>
              <Col md={8}>
                <h2 style={{ fontWeight: 900, fontSize: 30, color: "#00BFB1" }}>
                  ติดต่อ
              </h2>
                <br />
                <Detail />
                <br />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h2 style={{ fontWeight: 900, fontSize: 30, color: "#00BFB1" }}>
                  แผนที่
              </h2>
                <br />
                <MapApi />

              </Col>
            </Row>

          </Card>
        </Col>
      </Row>
    );
  }
}

export default Contact;
