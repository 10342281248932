import React, { Component } from "react";
import colorService from "../../../shared/services/ColorService";
import shapeService from "../../../shared/services/ShapeService";
import TypeMedicineService from '../../../shared/services/TypeMedicineService';
import { Form } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import makeAnimated from 'react-select/lib/animated';
import Select, { components } from 'react-select';
import chroma from 'chroma-js';
import './style.css';

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      // backgroundColor: isDisabled
      //   ? null
      //   : isSelected ? data.color : isFocused ? color.alpha(0.85).css() : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2 ? 'white' : 'black'
          : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: chroma.contrast(data.color, 'white') > 2 ? 'white' : 'black',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: chroma.contrast(data.color, 'white') > 2 ? 'white' : 'black'
  }),
};

class Advancedsearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      medicineSelect: [],
      colors: [],
      shapes: [],
      types: [],
      indexColor: 0
    };
  }

  componentWillMount() {
    this.setData();
  }

  setData = async () => {
    const colors = await colorService.getAllMainColor();
    const shapes = await shapeService.getShapeAll();
    const types = await TypeMedicineService.getTypeMedicine();
    this.setState({ colors, shapes, types });
  };

  onSelectShape = async item => {
    if (item) {
      this.props.onSelectShape(item);
    } else {
      this.props.onSelectShape({});
    }
  };

  onSelectColor = async colors => {
    if (colors.length <= 3) {
      this.props.onSelectColor(colors);
    }
  };

  onSelectMedicine = async type => {
    if (type) {
      this.props.onSelectMedicine(type);
    } else {
      this.props.onSelectMedicine({});
    }
  }
  renderMenu = (props) => {
    return (
      <components.Menu {...props}>
        <ul >
          {props.options.map((item, i) => {
            return (
              <li
                key={i}
                style={{ paddingLeft: 15, paddingRight: 15, cursor: "pointer" }}
                onClick={() => {
                  this.onSelectShape(item);
                  props.selectProps.onMenuClose()
                }}
              >
                <Row>
                  <Col lg={8} md={8} sm={8} xs={8}
                    style={{
                      display: "flex",
                      alignItems: "center"
                    }}>
                    <span>{item.value}</span>
                  </Col>
                  <Col lg={4} md={4} sm={4} xs={4} style={{ textAlign: "center" }}>
                    <img src={item.imgUrl} style={{ width: 35, height: 35 }} alt="shape" />
                  </Col>
                </Row>
              </li>
            )
          })}
        </ul>
      </components.Menu>
    );
  };
  render() {
    const { colors, shapes, types } = this.state;
    const { shape, type } = this.props;
    return (
      <Form>
        <Row>
          <Col md={4} style={{ marginTop: 15 }}>
            <p style={{ textAlign: "left", marginBottom: 10 }}>เลือกสี</p>
            <Select
              components={makeAnimated()}
              styles={colourStyles}
              placeholder={"เลือกสี...(ได้สูงสุด 3 สี)"}
              options={colors.map((item) => {
                return { value: item._id, label: item.colorName, color: item.hex }
              })}
              value={this.props.colors}
              onChange={this.onSelectColor}
              isMulti
            />
          </Col>
          <Col md={4} style={{ marginTop: 15 }}>
            <p style={{ textAlign: "left", marginBottom: 10 }}>เลือกรูปทรง</p>
            <Select
              isClearable
              components={{ Menu: this.renderMenu }}
              placeholder={"เลือกรูปทรง..."}
              value={Object.keys(shape).length > 0 ? shape : null}
              options={shapes.map((item) => {
                return { value: item.shape, label: item.shape, imgUrl: item.imageUrl }
              })}
              onChange={this.onSelectShape}
            />
          </Col>
          <Col md={4} style={{ marginTop: 15 }}>
            <p style={{ textAlign: "left", marginBottom: 10 }}>เลือกชนิดยา</p>
            <Select
              isClearable
              components={makeAnimated()}
              placeholder={"เลือกชนิดยา..."}
              defaultValue={Object.keys(type).length > 0 ? type : null}
              options={types.map((item) => {
                return { value: item.medicine, label: item.medicine, }
              })}
              onChange={this.onSelectMedicine}
            />
          </Col>
        </Row>
      </Form>
    );
  }
}
export default Advancedsearch;
