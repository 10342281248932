import React from 'react';
import { Container, Row, Col } from "react-bootstrap";

class Detail extends React.Component {
    render() {
        return (
            <Container style={{fontSize:16}}>
                <Row >
                    <Col md="auto">
                        <ion-icon name="home" /> ที่อยู่ติดต่อ
                    </Col>
                    <Col md="auto">
                        <p style={{ whiteSpace: "pre-line" }}>
                        {`- Mobile Application Developers Incubation Laboratory
                          - อาคารคณะวิทยาการสารสนเทศ ชั้นที่ 8
                          - 169 ถนนลงหาดบางแสน ต.แสนสุข อ.เมือง จ.ชลบุรี 20131`}
                        </p>
                    </Col>
                </Row>
                <Row >
                    <Col md="auto">
                        <ion-icon name="mail" /> E-mail
                    </Col>
                    <Col md="auto">
                        <p style={{ whiteSpace: "pre-line" }}>
                        {`madi.inform@gmail.com`}
                        </p>
                    </Col>
                </Row>
            </Container>
        )
    }

}

export default Detail;