import React from "react";
import TopicService from "../../../../shared/services/TopicService";
import { Row, Col, Card, Button } from "react-bootstrap";
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import SimpleReactValidator from "simple-react-validator";
class AddQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      datasCategory: [],
      topic: "",
      description: "",
      category: "",
      visibility: ""
    };
    this.validator = new SimpleReactValidator();
  }


  componentDidMount() {
    this.getCategory();
  };

  getCategory = async () => {
    this.setState({ loading: true });
    const datasCategory = await TopicService.getCategory();
    this.setState({ datasCategory, loading: false });
  };

  addTopic = async () => {
    if (this.validator.allValid()) {
      const data = {
        topic: this.state.topic,
        description: this.state.description,
        category: this.state.category,
        visibility: this.state.visibility
      };
      const status = await TopicService.addQuestion(data);
      status === 201 ? this.props.history.push("/question") : console.log(status);
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  };

  onChangeValue = (value, property) => {
    this.setState({ [property]: value });
  }

  render() {
    const { topic, description, category, visibility, datasCategory } = this.state;
    return (
      <Row
        className="justify-content-md-center"
        data-aos="fade-down"
        style={{
          marginTop: 50
        }}
      >
        <Col xl={7} lg={10} md={10}>
          <Card
            style={{
              padding: 20,
              boxShadow: "0 2px 7px 0 rgba(128, 128, 128, 0.2), 0 3px 10px 0 rgba(128, 128, 128, 0.19)"
            }}>
            <Row>
              <Col>
                <h1 style={{
                  fontSize: 30,
                  textAlign: "center",
                  color: "#4e98b8",
                  borderColor: "#4e98b8"
                }}>
                  ตั้งคำถาม
                </h1>
              </Col>
            </Row>
            <Row style={{ marginTop: 50 }}>
              <Col>
                <h1 style={{ fontSize: 18 }}>หัวข้อคำถาม :</h1>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <span style={{ fontSize: 18 }}>
                  {topic ? topic.length : 0}/120
                </span>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <input
                  className="input is-medium"
                  style={{
                    fontSize: 16,

                  }}
                  id="topic"
                  type="text"
                  value={topic}
                  onChange={e => this.onChangeValue(e.target.value, "topic")}
                  maxLength={120}
                />
                <small style={{ color: "red" }}>{this.validator.message('topic', topic, 'required|max:120')}</small>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col>
                <h1 style={{ fontSize: 18,fontFamily: "'Kanit', sans-serif" }}>รายละเอียด :</h1>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <span style={{ fontSize: 18 }}>
                  {description
                    ? description.length
                    : 0}
                  /2000
                </span>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <textarea
                  className="textarea"
                  style={{
                    fontSize: 16,
                    minHeight: 150
                  }}
                  id="description"
                  type="text"
                  value={description}
                  onChange={e => this.onChangeValue(e.target.value, "description")}
                  maxLength={2000}
                />
                <small style={{ color: "red" }}>{this.validator.message('description', description, 'required|max:2000')}</small>
              </Col>
            </Row>
            <Row>
              <Col xl={3} style={{ marginTop: 20 }}>
                <Select
                  components={makeAnimated()}
                  styles={{ control: (provided) => ({ ...provided, fontSize: 14 }) }}
                  placeholder='เลือกประเภทคำถาม'
                  value={category ? { value: category, label: category } : null}
                  options={datasCategory.map((item) => {
                    return { value: item.category, label: item.category }
                  })}
                  onChange={(option) => this.onChangeValue(option.value, "category")}
                />
                <small style={{ color: "red" }}>{this.validator.message('category', category, 'required')}</small>
              </Col>
              <Col xl={3} style={{ marginTop: 20 }}>
                <Select
                  components={makeAnimated()}
                  styles={{ control: (provided) => ({ ...provided, fontSize: 14 }) }}
                  placeholder='เลือกสิทธิการมองเห็น'
                  value={visibility ? { value: visibility, label: visibility } : null}
                  options={[
                    { value: "สาธารณะ", label: "สาธารณะ" },
                    { value: "ส่วนตัว", label: "ส่วนตัว" }
                  ]}
                  onChange={(option) => this.onChangeValue(option.value, "visibility")}
                />
                <small style={{ color: "red" }}>{this.validator.message('visibility', visibility, 'required')}</small>
              </Col>
            </Row>
            <Row style={{ marginTop: 30 }}>
              <Col md={12} xs={12} style={{ textAlign: "center" }}>
                <Button
                  onClick={() => this.props.history.goBack()}
                  style={{
                    fontSize: 14, marginRight: 10, borderRadius: "30px", color: "white", backgroundColor: "#4e98b8", borderColor: "#4e98b8", width: "100px",
                    height: "35px",
                    fontFamily: "'Kanit', sans-serif"
                  }}
                >
                  ยกเลิก
                </Button>
                <Button
                  type="submit"
                  onClick={this.addTopic}
                  style={{
                    fontSize: 14, borderRadius: "30px", color: "white", backgroundColor: "#4e98b8", borderColor: "#4e98b8", width: "100px",
                    height: "35px",
                    fontFamily: "'Kanit', sans-serif"
                  }}
                >
                  บันทึก
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default AddQuestion;
