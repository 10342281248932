import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import TopicService from "../../shared/services/TopicService";
import QuestionList from "../Question/QuestionList";
import { Search, Loading } from "../../components";
class MyQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            userAuth: {},
            currentPage: 1,
            numberOfDatas: 0,
            result: 10,
            datas: [],
            search: ""
        };
    }

    componentWillMount() {
        this.getTopic();
    }

    getTopic = async () => {
        const { currentPage, result, search } = this.state;
        this.setState({ loading: true });
        const data = await TopicService.getQuestionByUserId(currentPage - 1, result, search);
        this.setState({ datas: data.datas, numberOfDatas: data.numberOfDatas, markSearch: search, loading: false })
        window.scroll(0, 0);
    };

    onChangePage = (value) => {
        this.setState({ currentPage: value });
        this.getTopic();
    }

    onChange = async (e) => {
        await this.setState({ search: e.target.value });
    }

    onSearch = () => {
        this.getTopic();
    }

    handleKeyPress = (value) => {
        this.setState({ search: value })
        this.getTopic();
    }

    render() {
        const {
            loading,
            search,
            markSearch,
            datas,
            currentPage,
            numberOfDatas,
            result
        } = this.state;
        if (loading) {
            return <Loading />
        }
        return (
            <Row>
                <Col
                    xl={{
                        span: 8,
                        offset: 2
                    }}
                    md={{
                        span: 10,
                        offset: 1
                    }}>
                    <Row className="justify-content-md-center">
                        <h1
                            style={{
                                fontSize: '3.5vh',
                                textAlign: "center",
                                padding: 30,
                                color: "#4e98b8"
                            }}
                        >
                            คำถามของฉัน
                        </h1>
                    </Row>
                    <Row>
                        <Col
                            xl={{ span: 3, offset: 8 }}
                            lg={{ span: 3, offset: 8 }}
                            md={{ span: 4, offset: 8 }}
                            style={{
                                marginTop: 10
                            }}>
                            <Search
                                placeholder="ค้นหาคำถาม"
                                value={search}
                                onChange={this.onChange}
                                onSearch={this.onSearch}
                                onKeyPress={this.handleKeyPress} />
                        </Col>
                    </Row>
                    <Row style={{
                        marginTop: 10
                    }}>
                        <Col >
                            <QuestionList
                                datas={datas}
                                markSearch={markSearch}
                                currentPage={currentPage}
                                numberOfDatas={numberOfDatas}
                                result={result}
                                onChangePage={this.onChangePage}
                                history={this.props.history} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default MyQuestion;
