import {
    SET_PAGE_REQUEST,
    SET_PAGE_SUCCESS,
    SET_PAGE_FAILURE,
} from "../actions/PageAction/types";

const INITIAL_STATE = {
    currentPage: 1
};

const pageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PAGE_REQUEST:
            return { ...state };
        case SET_PAGE_SUCCESS:
            return { ...state, currentPage: action.json }
        case SET_PAGE_FAILURE:
            return { ...state, errorMessage: action.json }
        default:
            return state;
    }
};
export default pageReducer;