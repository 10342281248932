import React, { Component } from "react";
import './style.css'

class Footer extends Component {
  render() {
    return (
      <div>
        <hr style={{
          backgroundColor: "#DADADA"
        }} />
        <h1
          style={{
            color: "#DADADA",
            textAlign: "center",
            fontSize: 30
          }}>© สงวนลิขสิทธ์ คณะวิทยาการสารสนเทศ มหาวิทยาลัยบูรพา</h1>
        <h1
          style={{
            color: "#DADADA",
            textAlign: "center",
            fontSize: 15,
            marginBottom: 20
          }}>
          คณะวิทยาการสารสนเทศ 169 ถนนลงหาดบางแสน ต.แสนสุข อ.เมือง จ.ชลบุรี 20131 <br />
          โทรศัพท์/โทรสาร 038-103060 E-mail : www.informatics.buu.ac.th <br />
          Website Powered by MADI LAB
          </h1>
      </div>
    );
  }
}

export default Footer;
