import React from "react";
import TopicService from "../../../../../shared/services/TopicService";
import { Card, Row, Col, Button } from "react-bootstrap";
import makeAnimated from 'react-select/lib/animated';
import Select from 'react-select';
import SimpleReactValidator from "simple-react-validator";
import { Loading } from "../../../../../components";
class EditPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loding: false,
      data: [],
      datasCategory: []
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount = () => {
    const id = this.props.match.params.id;
    id ? this.getQuestionById(id) : this.props.history.goBack();
  };

  getQuestionById = async id => {
    this.setState({ loading: true });
    const data = await TopicService.getQuestionById(id);
    const datasCategory = await TopicService.getCategory();
    this.setState({ data, datasCategory, loading: false });
  };

  updateData = async () => {
    if (this.validator.allValid()) {
      this.setState({ loading: true });
      const status = await TopicService.updateQuestion(this.state.data);
      this.setState({ loading: false });
      const id = this.props.match.params.id;
      status === 200 ? this.props.history.replace(`/questiondetail/${id}`) : console.log(status);
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  };

  onDelete = () => {
    const id = this.props.match.params.id;
    TopicService.deleteQution(id);
    this.props.history.push(`/question`);
  };

  onChangeValue = (value, property) => {
    this.setState({
      data: { ...this.state.data, [property]: value }
    })
  }

  render() {
    const { loading, data, datasCategory } = this.state;

    if (loading) {
      return (<Loading />);
    }
    return (
      <Row
        className="justify-content-md-center"
        data-aos="fade-down"
        style={{
          marginTop: 50
        }}
      >
        <Col md={8}>
          <Card style={{ padding: 20 }}>
            <Row>
              <Col>
                <h1 style={{
                  fontSize: 30,
                  textAlign: "center",
                  color: "#4e98b8"
                }}>
                  แก้ไขคำถาม
                </h1>
              </Col>
            </Row>
            <Row style={{ marginTop: 50 }}>
              <Col>
                <h1 style={{ fontSize: 18 }}>หัวข้อคำถาม :</h1>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <span style={{ fontSize: "2vh" }}>
                  {this.state.topic ? this.state.topic.length : 0}/120
                </span>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <input
                  className="input is-medium"
                  style={{
                    fontSize: 16,

                  }}
                  id="topic"
                  type="text"
                  value={data.topic}
                  onChange={e => this.onChangeValue(e.target.value, "topic")}
                  maxLength={120}
                />
                <small style={{ color: "red" }}>{this.validator.message('topic', data.topic, 'required|max:120')}</small>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col>
                <h1 style={{ fontSize: 18 }}>รายละเอียด :</h1>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <span style={{ fontSize: "2vh" }}>
                  {this.state.description
                    ? this.state.description.length
                    : 0}
                  /2000
                </span>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <textarea
                  className="textarea"
                  style={{
                    fontSize: 16,
                    minHeight: 150
                  }}
                  id="description"
                  type="text"
                  value={data.description}
                  onChange={e => this.onChangeValue(e.target.value, "description")}
                  maxLength={2000}
                />
                <small style={{ color: "red" }}>{this.validator.message('description', data.description, 'required|max:2000')}</small>
              </Col>
            </Row>
            <Row>
              <Col xl={3} style={{ marginTop: 20 }}>
                <Select
                  components={makeAnimated()}
                  styles={{ control: (provided) => ({ ...provided, fontSize: 14 }) }}
                  placeholder='เลือกประเภทคำถาม'
                  value={data.category ? { value: data.category, label: data.category } : null}
                  options={datasCategory.map((item) => {
                    return { value: item.category, label: item.category }
                  })}
                  onChange={(option) => this.onChangeValue(option.value, "category")}
                />
                <small style={{ color: "red" }}>{this.validator.message('category', data.category, 'required')}</small>
              </Col>
              <Col xl={3} style={{ marginTop: 20 }}>
                <Select
                  components={makeAnimated()}
                  styles={{ control: (provided) => ({ ...provided, fontSize: 14 }) }}
                  placeholder='เลือกสิทธิการมองเห็น'
                  value={data.visibility ? { value: data.visibility, label: data.visibility } : null}
                  options={[
                    { value: "สาธารณะ", label: "สาธารณะ" },
                    { value: "ส่วนตัว", label: "ส่วนตัว" }
                  ]}
                  onChange={(option) => this.onChangeValue(option.value, "visibility")}
                />
                <small style={{ color: "red" }}>{this.validator.message('visibility', data.visibility, 'required')}</small>
              </Col>
            </Row>
            <Row style={{ marginTop: 30 }}>
              <Col md={12} xs={12} style={{ textAlign: "center" }}>
                <Button
                  size="md"
                  onClick={() => this.props.history.goBack()}
                  style={{ fontSize: 14, marginRight: 10, borderRadius: "30px", color: "white", backgroundColor: "#4e98b8", borderColor: "#4e98b8" ,fontFamily: "'Kanit', sans-serif"}}
                >
                  ยกเลิก
                </Button>
                <Button
                  size="md"
                  type="submit"
                  onClick={this.updateData}
                  style={{ fontSize: 14, borderRadius: "30px", color: "white", backgroundColor: "#4e98b8", borderColor: "#4e98b8",fontFamily: "'Kanit', sans-serif" }}
                >
                  บันทึก
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default EditPost;
