import React from "react";
// import Button from "@material-ui/core/Button";
import { Container, Row, Col, Button } from "react-bootstrap";
import UserService from "../../../shared/services/UserService";
import { ClipLoader } from "react-spinners";
import Alert from "../../../components/Alert";

class Confirmation extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      showMessage: false,
      errMessage: "",
      user: "",
      message: "",
      _id: "",
      token: "",
    };
  }
  async componentDidMount() {
    const verifyId = this.props.location.state ? this.props.location.state.id : "";
    verifyId ? this.setState({ _id: verifyId }) : this.props.history.goBack();
  }
  onSubmit = async () => {
    const res = await UserService.verifyToken(this.state._id, this.state.token);
    if (res.message === "Password verification failed") {
      this.Failed(res.message)
    } else if (res.message === "Password verification success") {
      this.nextPage(res.user)
    }
  }

  Failed = (message) => {
    this.setState({
      showMessage: true,
      email: "",
      errorMessage: message,
      loading: false
    });
  }

  handleDismiss = () => {
    this.setState({ showMessage: false });
  };

  nextPage = (id) => {
    this.props.history.push({
      pathname: `/Newpassword/${id._id}`,
      state: {
        user: id
      }
    })
  }
  render() {
    const { loading } = this.state
    return (
      <Container>
        <Row data-aos="fade-up" className="justify-content-md-center">
          <Col md={6}>
            <Row>
              <Col style={{ textAlign: "center" }}>
                <h1
                  style={{
                    fontSize: 30,
                    marginTop: 10,
                    marginBottom: 20,
                  }}
                >
                  รีเซ็ตรหัสผ่าน
                </h1>
                <p>ป้อนรหัสยืนยันที่เราส่งให้คุณที่อีเมล </p>
                <Alert
                  style={{ margin: "auto", color: "red", textAlign: "center", }}
                  show={this.state.showMessage}
                  variant="danger"
                  handleDismiss={this.handleDismiss}
                >
                  โปรดตรวจสอบ Token อีกครั้ง!
            </Alert>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <p style={{ color: "#4e98b8" }}>Token :</p>
              <input
                className="input"
                type="text"
                value={this.state.token}
                onChange={event =>
                  this.setState({ token: event.target.value })
                }
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    this.onSubmit()
                  }
                }}
              />
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col>
                <Button
                  className="button  is-medium is-fullwidth is-rounded "
                  style={{
                    backgroundColor: "#4e98b8",
                    color: "white",

                    marginTop: 20,
                    marginBottom: 30
                  }}
                  onClick={this.onSubmit}
                >
                  {loading ?
                    <ClipLoader
                      sizeUnit={"px"}
                      size={25}
                      color={"#ffffff"}
                    />
                    : <span>ดำเนินการต่อ</span>
                  }
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Confirmation;