import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
// import "./index.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../node_modules/react-image-gallery/styles/css/image-gallery.css";
// import "./App.css";
import { createBrowserHistory } from 'history';
import App from "./App";


const history = new createBrowserHistory();

const AppWithRouter= () =>(
  <Router history={ history }>
    <App history={ history } />
  </Router>
);

ReactDOM.render(<AppWithRouter />, document.getElementById("root"));
