import { put, takeLatest } from 'redux-saga/effects';
import {
    GET_USER_AUTH_REQUEST,
    GET_USER_AUTH_SUCCESS,
    GET_USER_AUTH_FAILURE,
    RESET_USER_AUTH_REQUEST,
    RESET_USER_AUTH_SUCCESS,
    RESET_USER_AUTH_FAILURE,
} from "../actions/UserAction/types";
import AuthGuardService from "../shared/services/AuthGuardService";
function* fetchUser() {
    try {
        const json = yield AuthGuardService.getUserAuth();
        yield put({ type: GET_USER_AUTH_SUCCESS, json: json });
    } catch (error) {
        yield put({ type: GET_USER_AUTH_FAILURE, json: error });
    }
}


function* resetUser() {
    try {
        const json = yield AuthGuardService.onSignOut();
        yield put({ type: RESET_USER_AUTH_SUCCESS, json: json });
    } catch (error) {
        yield put({ type: RESET_USER_AUTH_FAILURE, json: error });
    }
}

export default function * root() {
    yield takeLatest(GET_USER_AUTH_REQUEST, fetchUser);
    yield takeLatest(RESET_USER_AUTH_REQUEST, resetUser);
}
