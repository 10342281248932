import { ApiService } from './ApiService';

export const getDrugAll = async (page,result) => {
    const data = await ApiService.get(`/medicine/?page=${page}&result=${result}`)
    return data.data;
}

export const getMedicineName = async query => {
    const res = await ApiService.get(`/search/medicine/?name=${query}`)
    return res.data;
}

export const getMedicineByName = async (query , page , result) => {
    const res = await ApiService.get(`/medicine/name/?query=${query}&page=${page}&result=${result}`)
    return res.data;
}

export const getInfoDrugsById = async (id) => {
    const res = await ApiService.get(`/medicine/${id}`)
    return res.data;
}

export const getMedicineByFeture = async (body , page , result) =>{
    const res = await ApiService.post(`/medicine/feature/?page=${page}&result=${result}`,body)
    return res.data;
}

export const getMedicineByImage = async(imageUrl)=>{
    const res = await ApiService.get(`/mobile/medicine/image/?imageUrl=${imageUrl}`)
    return res.data
}
