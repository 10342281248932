import React, { Component } from "react";
import "bulma/css/bulma.css";
import "./App.css";
import { Header, ScrollTop } from "./components";
import { Route } from "react-router-dom";
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import reducers from "./reducers";
import rootSaga from './sagas';
import {
    Home,
    Medicine,
    Question,
    Wiki,
    QuestionDetail,
    AddQuestion,
    Login,
    Profile,
    EditPost,
    Register,
    EditProfile,
    Verify,
    MyQuestion,
    ConfirmPassword,
    ListResult,
    ImageSearch
} from "./containers";
import ResetPassword from "./containers/ResetPassword";
import Confirmation from "./containers/ResetPassword/Confirmation";
import NewPassword from "./containers/ResetPassword/NewPassword";
import Footer from "./components/Footer"

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    reducers,
    applyMiddleware(sagaMiddleware),
);
sagaMiddleware.run(rootSaga);
class App extends Component {
    render() {
        const history = this.props.history;
        return (
            <Provider store={store} history={history}>
                <Header history={history} />
                <div
                    style={{
                        marginTop: 0,
                        marginBottom: 0,
                        padding: 0
                    }}>
                    <Route exact path="/" component={Home} />
                    <Route path="/login" render={() => <Login history={history} />} />
                    <Route path="/profile" render={() => <Profile history={history} />} />
                    <Route path="/edit-profile" render={() => <EditProfile history={history} />} />
                    <Route path="/register" component={Register} />
                    <Route path="/verify/:id" component={Verify} />
                    <Route path="/medicine" render={() => <Medicine history={history} />} />
                    <Route path="/wiki/medicine/:id" component={Wiki} />
                    <Route path="/add-question" component={AddQuestion} />
                    <Route path="/question" component={Question} />
                    <Route path="/questiondetail/:id" component={QuestionDetail} />
                    <Route path="/editquestion/topic/:id/editPost" component={EditPost} />
                    <Route path="/my-question" component={MyQuestion} />
                    <Route path="/confirm-password" component={ConfirmPassword} />
                    <Route path="/ResetPassword" component={ResetPassword} />
                    <Route path="/Confirmation" component={Confirmation} />
                    <Route path="/NewPassword" component={NewPassword} />
                    <Route path="/ListResult" component={ListResult}/>
                    <Route path="/ImageSearch" component={ImageSearch} />
                </div>
                <Footer />
                <ScrollTop />
            </Provider>
        );
    }
}
export default App;
