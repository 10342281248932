import React, { Component } from "react";
import AuthGuardService from "../../shared/services/AuthGuardService";
import { Can, Loading } from "../../components";
import PharmQuestion from "./PharmQuestion";
import UserQuestion from "./UserQuestion";
class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            userAuth: {},
            currentPage: 1,
            numberOfDatas: 0,
            result: 10,
            datas: [],
            search: ""
        };
    }

    componentWillMount() {
        this.getUserAuth();
    }

    getUserAuth = async () => {
        this.setState({ loading: true });
        const userAuth = await AuthGuardService.getUserAuth();
        this.setState({ userAuth, loading: false });
    };

    render() {
        const { loading, userAuth } = this.state;
        if (loading) {
            return <Loading />
        }
        return (
            <div data-aos="fade-down">
                <h1
                    style={{
                        fontSize: '4vh',
                        textAlign: "center",
                        padding: 30,
                        color: "#4e98b8"
                    }}>
                    ปรึกษาปัญหากับผู้เชี่ยวชาญ
                </h1>
                <Can
                    role={userAuth.role}
                    perform="question-page:visit"
                    yes={() => (<PharmQuestion history={this.props.history} />)}
                    no={() => <UserQuestion history={this.props.history} />} />
            </div>
        );
    }
}

export default Question;
