import React, { Component } from "react";
import { ScaleLoader } from "react-spinners";
import { confirmAlert } from "react-confirm-alert";
import authGuardService from "../../../../shared/services/AuthGuardService";
import TopicService from "../../../../shared/services/TopicService";
import { Row, Col, Card, Dropdown } from "react-bootstrap";
import CustomToggle from "../CustomToggle";
import { Can } from "../../../../components";
import UserImage from "../../UserImage";
import AddAnswer from "../AddAnswer";

class Answer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      edit: false,
      answers: [],
      userAuth: {},
      text: "",
      editIndex: -1
    };
  }
  componentWillMount() {
    this.getData();
  }

  getData = async () => {
    this.setState({ loading: true });
    const userAuth = await authGuardService.getUserAuth();
    this.getAnswer();
    this.setState({ userAuth, loading: false });
  };

  getAnswer = async () => {
    const answers = await TopicService.getAnswerByQuestionId(this.props.questionId);
    this.setState({ answers });
  }

  onEditAnswer = (item, index) => {
    this.setState({ edit: true, text: item.text, editIndex: index });
  };

  onRemove = async item => {
    const res = await TopicService.deleteAnswer(item._id);
    if (res === 200) {
      this.getAnswer();
    }
  };

  submit = item => {
    confirmAlert({
      title: "ความคิดเห็น",
      message: "คุณต้องการลบความคิดเห็นนี้ใช่หรือไม่?",
      buttons: [
        {
          label: "ใช่",
          onClick: () => this.onRemove(item)
        },
        {
          label: "ไม่ใช่",
          onClick: () => console.log("on close")
        }
      ]
    });
  };

  offEditAns = () => {
    this.setState({ edit: false, editIndex: -1 });
  };

  updateData = async item => {
    const { text } = this.state;
    this.setState({ loading: true });
    const status = await TopicService.updateAnswer(item._id, { text });
    this.setState({ loading: false });
    if (status === 200) {
      this.getAnswer();
      this.setState({ edit: false, editIndex: -1 });
    }
  };

  addAnswer = async (value) => {
    this.setState({ loading: true });
    const data = {
      questionId: this.props.questionId,
      text: value
    };
    const status = await TopicService.addAnswers(data);
    this.setState({ loading: false });
    status === 201 ? this.getAnswer() : console.log(status)
  };

  render() {
    const { userAuth, loading, edit, answers, editIndex } = this.state;
    if (loading && answers) {
      return (
        <div className="sweet-loading">
          <h1 style={{ textAlign: "center" }}>
            <ScaleLoader
              sizeUnit={"px"}
              size={30}
              color={"#159EA3"}
              loading={loading}
            />
          </h1>
        </div>
      );
    }
    return (
      <div>
        <Row className="justify-content-md-center">
          <Col md={8} >
            {answers.map((item, index) => {
              return (
                <Card key={index} style={{
                  padding: 30,
                  marginBottom: 20,
                  boxShadow: `0 2px 7px 0 rgba(128, 128, 128, 0.2), 0 3px 10px 0 rgba(128, 128, 128, 0.19)`
                }}>
                  <Row>
                    <Col xl={11} lg={11} md={11} sm={10} xs={10}>
                      <p
                        style={{ fontSize: 18 }}
                      >
                        ความคิดเห็นที่ {index + 1}
                        <Can
                          role={item.answerer.role}
                          perform="text:list"
                          yes={() => (
                            <span style={{ fontSize: 15, color: "gray" }}>{" "}ตอบโดยผู้เชี่ยวชาญ</span>
                          )}
                          no={() => null}
                        />
                      </p>
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={2} xs={2}>
                      <Can
                        role={userAuth.role}
                        perform="posts:edit"
                        data={{
                          userId: userAuth._id,
                          postOwnerId: item.answerer._id
                        }}
                        yes={() => (
                          <Row className="justify-content-md-right" style={{ padding: 5 }}>
                            <Col>
                              <Dropdown>
                                <Dropdown.Toggle
                                  as={CustomToggle}
                                  id="dropdown-custom-components"
                                >
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ right: 0, left: "auto" }}>
                                  <Dropdown.Item eventKey="1" onClick={() => this.onEditAnswer(item, index)}>
                                    <ion-icon name="create" />
                                    <span>แก้ไข</span>
                                  </Dropdown.Item>
                                  <Dropdown.Item eventKey="2" onClick={() => this.submit(item)}>
                                    <ion-icon name="trash" />
                                    <span>ลบ</span>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Col>
                          </Row>
                        )}
                        no={() => null}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {edit && editIndex === index ?
                      (
                        <Col>
                          <Row>
                            <textarea
                              className="textarea "
                              placeholder="เขียนความคิดเห็น"
                              id="text"
                              type="text"
                              style={{ marginTop: 10 }}
                              value={this.state.text}
                              onChange={event =>
                                this.setState({ text: event.target.value })
                              }
                            />
                          </Row>
                          <Row >
                            <Col style={{ textAlign: "right" }}>
                              <h6
                                style={{ marginTop: 10, borderRadius: "30px", color: "white", backgroundColor: "#4e98b8", borderColor: "#4e98b8" }}
                                onClick={() => this.updateData(item)}
                                className="button"
                              >
                                อัพเดต
                            </h6>
                              <h6
                                style={{ marginLeft: 10, marginTop: 10, borderRadius: "30px", color: "white", backgroundColor: "#4e98b8", borderColor: "#4e98b8" }}
                                onClick={this.offEditAns}
                                className="button"
                              >
                                ยกเลิก
                            </h6>
                            </Col>
                          </Row>
                        </Col>
                      ) : (
                        <Col>
                          <UserImage
                            size={40}
                            user={item.answerer}
                            dataTime={item.createdAt}
                          />
                          <p style={{ fontSize: 16 }}>{item.text}</p>
                        </Col>
                      )}
                  </Row>
                </Card>
              );
            })}
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={8}>
            <AddAnswer onSubmit={this.addAnswer} questionId={this.props.questionId} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Answer;
