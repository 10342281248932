import { ApiService } from "./ApiService";
import authGuardService from "./AuthGuardService";
const service = {};

service.getUserProfile = async () => {
    const res = await ApiService.get("/user/profile");
    if(res.status === 200){
      await authGuardService.setUserAuth(res.data);
    }
};

service.updateProfile = async data => {
  const res = await ApiService.put(`/user/profile`, data);
  if (res.status === 200) {
    await authGuardService.onSignIn(res.data);
    await service.getUserProfile();
  }
  return res.status;
};

service.verifyPassword = async (id, body) => {
  const res = await ApiService.put(`/user/password/${id}`, body);
  return res.status;
}

service.updateImageProfile = async (user, image) => {
  const formData = new FormData();
  await formData.append("image", image)
  const headers = {
    Accept: "application/json",
    "content-type": "multipart/form-data"
  }
  const upload = await ApiService.post(`/upload/image/profile/?type=profile`, formData, headers)
  if (upload.status === 200) {
     user.imageUrl = upload.data.imageUrl;
     const status = await service.updateProfile(user);
     return status;
  }
  return upload.status;
}

service.signIn = async body => {
  const res = await ApiService.get(`/user/login/?email=${body.email}&password=${body.password}`);
  let result = { 
    message: res.data.message, 
    user: res.data.user
  };
  if (res.data.message === "Login successful") {
    await authGuardService.onSignIn(res.data.access_token);
    await service.getUserProfile();
  }
  return result;
};

service.register = async body => {
  const platform = "web";
  const res = await ApiService.post(`/user/register/?platform=${platform}`, body);
  return res.data;
};

service.verify = async (id) => {
  const res = await ApiService.get(`/user/verify/${id}`);
  return res.data;
}

service.sendEmailAgain = async (id) => {
  const res = await ApiService.get(`/user/verify/${id}/again`);
  return res.status;
}

service.signOut = async () => {
  const res = await authGuardService.onSignOut();
  return res; 
};

service.sendTokenToEmail = async (email) => {
  const res = await ApiService.get(`/user/resetPassword?email=${email}`);
  return res.data
}

service.verifyToken = async  (_id,token) => {
  const res = await ApiService.get(`/user/resetPassword/${_id}?token=${token}`);
  return res.data

}

service.resetPassword = async (_id,data) => {
  const res = await ApiService.put(`/user/resetPassword/${_id}`, data);
  return res
}
export default service;
