import React, { Component } from "react";
import { Container, Row, Col, Image, Alert, Button } from "react-bootstrap";
// import Button from "@material-ui/core/Button";
import UserService from "../../shared/services/UserService";
import { ClipLoader } from "react-spinners";
import { Loading } from "../../components"
class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sending: false,
      isVerified: false,
      showMessage: false,
      errMessage: "",
      user: "",
      message: "",
      variant: "success",
      verifyId: ""
    };
  }

  async componentDidMount() {
    const message = this.props.location.state ? this.props.location.state.message : "";
    const verifyId = this.props.match.params.id;
    await this.setState({ verifyId });
    if (message.trim()) {
      this.setState({ message });
    } else {
      this.checkVerify();
    }
  }

  checkVerify = async () => {
    this.setState({ loading: true });
    const res = await UserService.verify(this.state.verifyId);
    this.setState({ loading: false });
    if (res.message === "Email verification success") {
      this.setState({ isVerified: true, confirmMessage: "อีเมลของคุณได้รับการยืนยันเรียบร้อยแล้ว", icon: "success", user: res.user });
    } else if (res.message === "Verified email") {
      this.setState({ isVerified: true, confirmMessage: "คุณไม่สามารถยืนยันการใช้งานอีเมลซ้ำได้ เนื่องจากคุณได้ทำการยืนยันการใช้งานไปแล้ว!", icon: "warning" });
    } else {
      this.setState({ isVerified: true, confirmMessage: "การยืนยันการใช้งานอีเมลล้มเหลว โปรดลองใหม่อีกครั้ง!", icon: "failed" });
    }
  }

  hideErrMessage = () => {
    setTimeout(() => {
      this.setState(() => ({
        errMessage: ""
      }))
    }, 5000)
  }

  verifyFailed() {
    this.setState({ errMessage: "การยืนยันอีเมลล้มเหลว: รหัสยืนยันไม่ถูกต้อง กรุณาตรวจใหม่อีกครั้ง!" });
    this.hideErrMessage();
  }

  nextPage = () => {
    const { user } = this.state;
    if (user.role === "pharmacist") {
      this.props.history.push({
        pathname: '/confirm-password',
        state: { user: user }
      })
    } else {
      this.props.history.push("/login");
    }
  }

  sendAgain = async () => {
    if (!this.state.sending) {
      this.setState({ sending: true });
      const status = await UserService.sendEmailAgain(this.state.verifyId);
      if (status === 200) {
        this.setState({
          showMessage: true,
          message: "ระบบทำการส่งรหัสยืนยันไปที่อีเมลของคุณเรียบร้อย!",
          variant: "success",
          sending: false
        })
      } else {
        this.setState({
          showMessage: true,
          message: "การส่งรหัสยืนยันล้มเหลว โปรดลองใหม่อีกครั้ง!",
          variant: "danger",
          sending: false
        })
      }
      setTimeout(() => {
        this.setState(() => ({
          showMessage: false
        }))
      }, 3000)
    }
  };

  getIcon = (icon) => {
    if (icon === "success") {
      return require("../../assets/images/icons/success.png");
    } else if (icon === "warning") {
      return require("../../assets/images/icons/warning.png");
    } else {
      return require("../../assets/images/icons/failed.png");
    }
  }
  render() {
    const { loading, isVerified, sending, showMessage, variant, message, confirmMessage, icon } = this.state;
    if (loading) {
      return (<Loading />)
    }
    return (
      <Container>
        <h1 style={{ color: "#4e98b8", fontSize: 30, textAlign: "center", padding: 30 }}>ยืนยันอีเมล</h1>
        <Row className="justify-content-md-center">
          {isVerified ? (
            <Col md={6} style={{ textAlign: "center" }}>
              <Image
                style={{ width: "15vh", height: "15vh", padding: 10 }}
                src={this.getIcon(icon)}
              />
              <h1 style={{ marginTop: 20, fontSize: 18 }}>
                {confirmMessage}
              </h1>
              <Button
                className="button  is-medium is-fullwidth is-rounded "
                style={{
                  backgroundColor: "#4e98b8",
                  color: "white",

                  marginTop: 20
                }}
                onClick={this.nextPage}
              >
                เสร็จสิ้น
              </Button>
            </Col>
          ) : (
              <Col md={6} style={{ marginTop: 20 }}>
                <Row >
                  <Col>
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                  </Col>
                </Row>
                <Row style={{ marginTop: 30 }}>
                  <Col style={{ textAlign: "center" }}>
                    <Button
                      className="button  is-medium is-fullwidth is-rounded "
                      style={{
                        backgroundColor: "#4e98b8",
                        color: "white",

                        marginTop: 20
                      }}
                      onClick={this.nextPage}
                    >
                      เสร็จสิ้น
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div style={{ marginTop: 20, textAlign: "center" }}>
                      {showMessage ?
                        <Alert variant={variant}>
                          <div dangerouslySetInnerHTML={{ __html: message }} />
                        </Alert>
                        :
                        <span
                          onClick={this.sendAgain}
                          style={{
                            cursor: "pointer",
                            textAlign: "center"
                          }}>
                          {sending ? (
                            <ClipLoader
                              sizeUnit={"px"}
                              size={20}
                            />
                          ) : (
                              <span><b>ส่งรหัสยืนยันอีกครั้ง</b> <ion-icon name="refresh" /></span>
                            )}
                        </span>}
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
        </Row>
      </Container>
    );
  }
}
export default Verify;