
const service = {};

service.getTypeArticle = () => {
    return type;
}


const type = [
    { id: 0, type:"article" , name:"บทความ"},
    { id: 1, type:"infographic" , name:"แผ่นภาพ" },
    
  ];


  
export default service;