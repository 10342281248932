import React from "react";
// import Button from "@material-ui/core/Button";
import Alert from "../../components/Alert";
import { Container, Row, Col, Button } from "react-bootstrap";
import UserService from "../../shared/services/UserService";
import { ClipLoader } from "react-spinners";

class ResetPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "",
      loading: false,
      _id: "",
      errorMessage: "",
      showMessage: false
    };
  }
  nextPage = (id) => {
    this.props.history.push({
      pathname: `/Confirmation/${id}`,
      state: {
        id: id,
      }
    })
  }
  resetPassword = async () => {
    this.setState({ loading: true });
    const data = this.state.email;
    const res = await UserService.sendTokenToEmail(data);
    this.setState({ loading: false });
    if (res.message === "Email not found") {
      this.Failed(res.message)
    } else if (res.message === "Successfully sent a token") {
      this.setState({ _id: res._id, email: res.email })
      this.nextPage(res._id)
    }
  }

  Failed = (message) => {
    this.setState({
      showMessage: true,
      email: "",
      errorMessage: message,
      loading: false
    });
  }

  handleDismiss = () => {
    this.setState({ showMessage: false });
  };

  render() {
    const { loading } = this.state
    return (
      <Container>
        <Row data-aos="fade-up" className="justify-content-md-center">
          <Col md={6}>
            <Row >
              <Col style={{ textAlign: "center" }}>
                <h1
                  style={{
                    fontSize: 30,
                    marginTop: 10,
                    marginBottom: 20,

                  }}
                >
                  รีเซ็ตรหัสผ่าน
                </h1>
                <p>โปรดกรอกอีเมลเพื่อทำการส่งรหัสยืนยันไปที่อีเมลของคุณ</p>
                <Alert
                  style={{ margin: "auto", color: "red", textAlign: "center", }}
                  show={this.state.showMessage}
                  variant="danger"
                  handleDismiss={this.handleDismiss}
                >
                  โปรดตรวจสอบ Email อีกครั้ง!
            </Alert>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <p style={{ color: "#159EA3", }}>อีเมล : </p>
              <input
                className="input"
                type="text"
                placeholder="email@example.com"
                value={this.state.email}
                onChange={event =>
                  this.setState({ email: event.target.value })
                }
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    this.resetPassword()
                  }
                }}
              />
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col>
                <Button
                  className="button  is-medium is-fullwidth is-rounded "
                  style={{
                    backgroundColor: "#4e98b8",
                    color: "white",

                    marginTop: 20,
                    marginBottom: 30
                  }}
                  onClick={this.resetPassword}
                >
                  {loading ?
                    <ClipLoader
                      sizeUnit={"px"}
                      size={25}
                      color={"#ffffff"}
                    />
                    : <span>ส่งรหัสยืนยัน</span>
                  }
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default ResetPassword;