import React, { Component } from "react";
import ImageUpload from "../ImageUpload/index";
import * as DrugService from "../../../shared/services/DrugService";
import { Loading } from "../../../components";
import { Container, Row, Col, Button, Image, Table } from "react-bootstrap";

class ImageSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: true,
            imageUrl: "",
            data: [],
            setdata: [],
            result: 12,
            numberOfMedicine: 0
        };
        this.scroller = null
    }

    componentWillMount() {
        try {
            this.setState({
                data: this.props.location.state.data,
                imageUrl: this.props.location.state.imageUrl
            })
        } catch (error) {
        }
    }

    fetchRecords = async () => {
        const { result } = this.state
        this.setState({ loading: true })
        const body = this.state.imageUrl
        if (body === "") {
            await this.setState({ data: [], loading: false })
        } else {
            const data = await DrugService.getMedicineByImage(body.imageUrl)
            await this.setState({
                data: data.results,
                numberOfPages: Math.ceil(data.results.length / result),
                numberOfMedicine: data.results.length,
                loading: false,
                visible: false,
            })
        }
        window.scroll(0, 0);
    }

    searchImageMedicine = async () => {
        this.fetchRecords()
    }

    handleBackWard = async () => {
        this.props.history.push({
            pathname: '/medicine'
        });
    }

    handleChange = files => {
        this.setState({ imageUrl: "" })
        this.setState({ imageUrl: files })
    }

    setDataSearchImage = async (index, shape, shapePoint, color, colorPoint) => {
        this.props.history.push({
            pathname: `/ListResult`,
            state: {
                index: index,
                shape: shape,
                shapePoint: shapePoint,
                color: color,
                colorPoint: colorPoint,
                imageUrl: this.state.imageUrl,
                data: this.state.data
            }
        })
    }

    render() {
        const { loading, visible, data, imageUrl, numberOfMedicine } = this.state;
        if (loading) {
            return <Loading />
        } else {
            return (
                <Container onScroll={this.handleScroll} ref={(scroller) => this.scroller = scroller}>
                    <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                        <p style={{ fontSize: 30, color: "#4e98b8", textAlign: "center" }}>ค้นหาด้วยภาพถ่าย</p>
                        <Row className="justify-content-md-center">
                            <Col style={{ marginTop: 50, textAlign: "center" }}>
                                <Row className="justify-content-md-center" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
                                    {imageUrl === "" ?
                                        null
                                        :
                                        <Image src={imageUrl.imageUrl} style={{ width: '30vh', height: '30vh' }} />
                                    }
                                </Row>
                                <ImageUpload
                                    onChange={this.handleChange}
                                />
                            </Col>
                            <Col md={12} style={{ marginTop: 50, textAlign: "center" }}>
                                <Button
                                    style={{ marginRight: 10, width: 150, color: "white", backgroundColor: "#4e98b8", borderRadius: "30px", borderColor: "#4e98b8" }}
                                    onClick={this.searchImageMedicine}
                                >
                                    <p style={{ color: "white" }}>ค้นหา</p>
                                </Button>

                                <Button
                                    size="md"
                                    onClick={this.handleBackWard}
                                    style={{ width: 150, borderColor: "#4e98b8", backgroundColor: "#4e98be", borderRadius: "30px" }}
                                >
                                    <p style={{ color: "white" }}>ย้อนกลับ</p>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                    <hr />
                    {(visible) ? null :
                        <h1 style={{ fontSize: '14px', color: "grey", marginBottom: "2vh", textAlign: "Left" }}>
                            ผลลัพธ์ทั้งหมด: {numberOfMedicine} รายการ
                        </h1>
                    }
                    {(data && data.length > 0) ?
                        <Table responsive striped bordered variant="info">
                            <thead style={{ backgroundColor: "#5bc0de", fontSize: "20px" }}>
                                <tr>
                                    <th>ลำดับ</th>
                                    <th>สี</th>
                                    <th>รูปทรง</th>
                                    <th>เพิ่มเติม</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {item.color.map((color, index) => { return item.color.length > 1 ? (`${index + 1}.) ${color.color} (ความคล้าย: ${color.point}%) `) : (`${color.color} (ความคล้าย: ${color.point}%) `) })}
                                        </td>
                                        <td>{item.shape.shape} (ความคล้าย: {item.shape.point}%)</td>
                                        <td>
                                            <Button
                                                onClick={() => this.setDataSearchImage(index, item.shape.shape, item.shape.point, item.color.map((color, index) => { return color.color }), item.color.map((color, index) => { return color.point }))}
                                                variant="link">ดูรายการ</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        :
                        (visible) ? null :
                            <div>
                                <h1 style={{ textAlign: "center", fontSize: '60wh', color: "#4e98b8" }}>"ไม่พบข้อมูลยาในระบบ"</h1>
                            </div>
                    }
                </Container >
            );
        }
    }
}

export default ImageSearch;