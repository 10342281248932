import axios from 'axios';
import join from 'url-join';
import authGuardService from './AuthGuardService';

var isAbsoluteURLRegex = /^(?:\w+:)\/\//;

axios.interceptors.request.use(async (config) => {
    if (!isAbsoluteURLRegex.test(config.url)) {
        const jwtToken = await authGuardService.getToken();
        if (!config.headers["content-type"]) {
            config.headers = {
                'Content-Type': 'application/json'
            }
        }
        if (jwtToken) {
            config.headers['Authorization'] = `${jwtToken}`;
        }
        config.url = join('https://clinicya.buu.ac.th/api', config.url);
        
        
        



    }
    return config;
})

export const ApiService = axios;