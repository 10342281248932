import React, { Component } from "react";
import {  Row } from "react-bootstrap";
class PaginationCustom extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentPage: this.props.currentPage
        }
    }
    
    componentWillReceiveProps(nextProps) {
        if (nextProps.currentPage !== this.props.currentPage) {
            this.setState({ currentPage: nextProps.currentPage})
        }
    }

    next = () => {
        let page = this.props.currentPage < this.props.numOfPages ? this.props.currentPage + 1 : this.props.numOfPages;
        this.setState({ currentPage: page });
        this.props.nextPage(page);
    }

    previous = () => {
        let page = this.props.currentPage > 1 ? this.props.currentPage - 1 : 1;
        this.setState({ currentPage: page });
        this.props.previousPage(page);
    }
    onChange = (e) => {
        const value = parseInt(e.target.value);
        this.setState({ currentPage: value ? value : 0 });
    }
    handleKeyPress = (e) => {
        if (e.key === "Enter") {
            let page = parseInt(e.target.value);
            if (page) {
                if (page > 0 && page <= this.props.numOfPages) {
                    this.setState({ currentPage: page });
                    this.props.onChange(page);
                } else if (page > this.props.numOfPages) {
                    this.setState({ currentPage: this.props.numOfPages});
                    this.props.onChange(this.props.numOfPages);
                } else {
                    this.setState({ currentPage: 1 });
                    this.props.onChange(1);
                }
            } else {
                this.props.onChange(this.props.currentPage);
                this.setState({ currentPage: this.props.currentPage });
            }
        }
    }

    render() {
        const { currentPage } = this.state;
        const styles = { borderColor: "#d2d2d2", borderStyle: "solid", textAlign: "center" }
        return (
            <Row className="justify-content-md-center">
                <button
                    onClick={this.previous}
                    style={{
                        ...styles,
                        width: "30%",
                        lineHeight: "34px",
                        backgroundColor: "#4e98b8",
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: 20,
                        outline: "none",
                        
                    }}
                    disabled={currentPage === 1 || this.props.numOfPages === 0 ? true : false}
                >
                    <span style={{ color: "#ffffff", fontSize: 15, textAlign: "center" }}>ย้อนกลับ</span>
                </button>
                <input
                    style={{
                        ...styles,
                        lineHeight: "34px",
                        borderWidth: ".75px .75px .75px 0px",
                        width: "20%",
                        fontSize: 15,
                        
                    }}
                    value={this.props.numOfPages > 0 ? currentPage : this.props.numOfPages}
                    onChange={this.onChange}
                    onKeyPress={this.handleKeyPress}
                    maxLength={4}
                    disabled={this.props.numOfPages === 0 ? true : false}
                />
                <div
                    style={{
                        ...styles,
                        lineHeight: "36px",
                        borderWidth: ".75px 0px .75px 0px",
                        width: "20%",
                        textAlign: "center",
                        paddingLeft: 2,
                        paddingRight: 2,
                        
                    }}
                >
                    <span> of {this.props.numOfPages}</span>
                </div>
                <button
                    style={{
                        ...styles,
                        lineHeight: "34px",
                        width: "30%",
                        backgroundColor: "#4e98b8",
                        borderTopRightRadius: 20,
                        borderBottomRightRadius: 20,
                        outline: "none",
                        
                    }}
                    onClick={this.next}
                    disabled={this.props.numOfPages === currentPage || this.props.numOfPages === 0 ? true : false}
                >
                    <span style={{ color: "#ffffff", fontSize: 15, textAlign: "center" }}>ถัดไป</span>
                </button>
            </Row>
        )
    }
}

export default PaginationCustom;